'use strict';

import {DateFieldToNumber} from './constants';

export default class BulkUpdate {
    static process(csv, ignoreHeaders, progressCallback) {
        return new Promise(async(res,rej) => {
            if(csv.rows.length === 0) {
                rej("No data");
                return;
            }

            try {
                await Littlstar.all;
            }
            catch (e) {
                rej(e);
                return;
            }

            //we ignore header row
            let start = 0;
            if(ignoreHeaders) start = 1;

            for(let i = start; i < csv.rows.length; i++) {
                //first field is slug
                const slug = csv.rows[i][0].trim();
                //second field is tags
                let tags = csv.rows[i][1];

                if(tags) {
                    tags = tags.trim();
                }

                if(tags.length > 0) tags = tags.split(',');

                //preprocess tags aka trim and lower case
                for(let j = 0; j < tags.length; j++) {
                    let tag = tags[j];
                    tag = tag.trim().toLowerCase();
                    tags[j] = tag;
                }

                let release = csv.rows[i][2];
                //third field is release date
                if(release) {
                    release = release.trim();
                    release = DateFieldToNumber(release);
                }

                //fourth field if any is title
                let title = csv.rows[i][3];

                if(title) {
                    title = title.trim();
                }

                //fifth field if any is description
                let description = csv.rows[i][4];

                if(description) {
                    description = description.trim();
                }

                try {
                    const video = await Littlstar.getVideoBySlug(slug);

                    if(video) {
                        if(title || description) {
                            try {
                                await Keywords.updateDescription(slug, video.category, description, title);
                            }
                            catch (e) {
                                console.log(e);
                            }
                        }

                        if(release) {
                            try {
                                await Keywords.updateReleaseDate(slug, video.category, release);
                            }
                            catch(e) {
                                console.log(e);
                            }
                        }

                        if(tags && tags.length > 0) {
                            try {
                                await Keywords.updateKeywords(slug, video.category, tags);
                            }
                            catch (e) {
                                console.log(e);
                                console.log('invalid formatting for tags on  row: ' + (i + 1));
                            }
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                }

                if(progressCallback) {
                    progressCallback((i + 1) / csv.rows.length * 100);
                }
            }

            res(true);
        });
    }
}