"use strict";

import { MyndInput, MyndButton, ListView, MyndFloatingButton, MyndSelect, MyndOption } from "./common";
import Communities from "../logic/community";
import { Login } from "../logic/login";
import { DateFieldToNumber, DateNumberToDateField } from "../logic/constants";

class CommunityEditView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.onClick = this.onClick.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.inputTimeout = null;
        this.input = null;
        this.inputCommunity = null;
        this.inputParent = null;
    }

    async onDelete(e) {
        e.stopPropagation();
        e.preventDefault();

        const doDelete = confirm(`Delete this community?`);

        if (!doDelete) {
            return;
        }

        try {
            const succ = await this.remove();

            if (this.parent) {
                await this.parent.refresh();
            }

            location.hash = "#/communities";
        } catch (e) {}
    }

    async remove() {
        try {
            return await Communities.remove(this.item.name);
        } catch (e) {
            console.log(e);
        }

        return false;
    }

    async onClick(e) {
        e.stopPropagation();
        e.preventDefault();

        await this.save();

        location.hash = "#/communities";
    }

    async save() {
        try {
            if (this.inputParent.value) {
                const parent = this.inputParent.value;
                this.item.parentCompany = parent;
                await Communities.updateParentCompany(this.item.name, parent);
            }
        } catch (e) {
            console.log(e);
        }

        try {
            if (this.input.value) {
                const time = DateFieldToNumber(this.input.value);
                this.item.activationDate = time;
                await Communities.updateActivation(this.item.name, time);
            }
        } catch (e) {
            console.log(e);
        }

        try {
            if (this.inputCommunity.value) {
                const time = DateFieldToNumber(this.inputCommunity.value);
                this.item.communityActivationDate = time;
                await Communities.updateCommunityActivation(this.item.name, time);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async render() {
        const hasKnown = this.item.seenUsers ? this.item.seenUsers.length > 0 : false;
        return (
            <div class="min-width-512 max-width-512 mr-auto ml-auto rounded bg-light material-shadow" style="margin-top: 64px;">
                <h1 class="display-5 p-4 bg-dark rounded text-white">{this.item.name}</h1>
                <div class="mt-20">
                    <div class="pl-4 pr-4 rounded">
                        <MyndInput
                            type="text"
                            iref={(f) => (this.inputParent = f)}
                            required={true}
                            placeholder="parent company name"
                            value={this.item.parentCompany}
                            pattern={`^[A-Za-z0-9\\. \']+$`}
                        />
                        <MyndInput
                            type="date"
                            iref={(f) => (this.inputCommunity = f)}
                            min="2018-02-01"
                            required={true}
                            placeholder="community activation date"
                            value={DateNumberToDateField(this.item.communityActivationDate, !this.item.communityActivationDate)}
                        />
                    </div>
                    <p class="bg-white p-4">
                        To allow access to all videos set content activation date to 02/01/2018 (Initial Release Epoch). Otherwise, what is available
                        is based on the amount of days from content activation date to today's date.
                    </p>
                    <div class="pl-4 pr-4 rounded">
                        <MyndInput
                            type="date"
                            iref={(f) => (this.input = f)}
                            min="2018-02-01"
                            required={true}
                            placeholder="content activation date"
                            value={DateNumberToDateField(this.item.activationDate, false)}
                        />
                    </div>
                    <div class="p-4 m-4 bg-secondary rounded text-white">
                        <h5>Known Users</h5>
                        <p>{hasKnown ? this.item.seenUsers.join(", ") : "None"}</p>
                    </div>
                </div>
                <div class="d-flex w-100 mt-2 pl-4 pr-4 pb-4 justify-content-between">
                    <MyndButton type="danger" onclick={this.onDelete}>
                        DELETE
                    </MyndButton>
                    <MyndButton onclick={this.onClick}>DONE</MyndButton>
                </div>
            </div>
        );
    }
}

class CommunityListItem extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        e.stopPropagation();
        e.preventDefault();

        location.hash = `#/communities?edit=${this.index}`;
    }

    async render() {
        return (
            <div class="list-item pointer" onclick={this.onClick}>
                <h2 class="title">{this.item.name}</h2>
            </div>
        );
    }
}

export class CommunitiesListSelect extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            items: [],
        });
        this.onChange = this.onChange.bind(this);
        this.cache = null;
    }

    onChange(e, value) {
        this.selected = value;

        if (this.onchange) {
            this.onchange(value);
        }
    }

    async componentDidMount() {
        this.refresh();
    }

    async refresh() {
        const items = [];
        try {
            let page = null;
            console.log("getting communities for list select");
            if (!this.cache) {
                page = await Communities.all();
                this.cache = page;
            } else {
                page = this.cache;
            }

            if (page.items) {
                page.items.sort((a, b) => {
                    if (a.parentCompany < b.parentCompany) {
                        return -1;
                    } else if (a.parentCompany > b.parentCompany) {
                        return 1;
                    } else {
                        if (a.name < b.name) {
                            return -1;
                        } else if (a.name > b.name) {
                            return 1;
                        }

                        return 0;
                    }
                });
            }

            for (let i = 0; i < page.total; i++) {
                if (!this.selected) {
                    this.selected = page.items[i].name;

                    //if we weren't provided a default selection
                    //callback with the proper thing
                    if (this.onchange) {
                        this.onchange(this.selected);
                    }
                }

                items.push(
                    <MyndOption
                        key={`community-box-${i}`}
                        value={page.items[i].name}
                        text={page.items[i].name}
                        selected={this.selected === page.items[i].name ? "selected" : null}
                    />
                );
            }
        } catch (e) {
            console.log(e);
        }

        this.items = items;
    }

    async render() {
        return <MyndSelect placeholder="community" onchange={this.onChange} value={this.selected} items={this.items} />;
    }
}

class CommunityAddView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.name = "";

        this.observe({
            error: "Invalid name format",
        });
        this.forceError = false;
        this.onInput = this.onInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.name.match(/^[A-Za-z0-9\. ']+$/gi)) {
            try {
                await Communities.add(this.name);

                if (this.onSubmission) {
                    this.onSubmission();
                }
            } catch (e) {
                this.forceError = true;
                this.error = "Name already in use";
                console.log(e);
            }
        }
    }

    onInput(e) {
        this.name = e.target.value;

        if (!this.name.match(/^[A-Za-z0-9\. ']+$/gi) && this.error !== "Invalid name format") {
            this.forceError = false;
            this.error = "Invalid name format";
        }
    }

    async render() {
        return (
            <div class="bg-white p-2 min-width-512 max-width-512 material-shadow" style="position: fixed; right: 64px; bottom: 64px;">
                <MyndInput
                    type="text"
                    placeholder="community name"
                    pattern={`^[A-Za-z0-9\\. \']+$`}
                    required={true}
                    value={this.name}
                    oninput={this.onInput}
                    error={this.error}
                    forceError={this.forceError}
                />
                <MyndButton onclick={this.onSubmit}>CREATE</MyndButton>
            </div>
        );
    }
}

export default class CommunitiesView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            communities: [],
            showAdd: false,
        });
        this.onToggleAddClick = this.onToggleAddClick.bind(this);
        this.onSubmission = this.onSubmission.bind(this);
        this.errorMessage = "Loading";
        this.items = null;
    }

    onToggleAddClick(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!this.showAdd) {
            this.showAdd = true;
        } else if (this.showAdd) {
            this.showAdd = false;
        }
    }

    async onSubmission() {
        this.onToggleAddClick();
        this.refresh();
    }

    async componentDidMount() {
        this.refresh();
    }

    async refresh() {
        if (Login.hasGroup("Admin")) {
            try {
                console.log("refreshing community list");
                const page = await Communities.all();
                const items = [];

                console.log(page);

                this.items = page.items;

                this.items.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    } else if (a.name > b.name) {
                        return 1;
                    }
                });

                for (let i = 0; i < page.total; i++) {
                    const item = page.items[i];
                    items.push(<CommunityListItem key={`community-${i}`} item={item} index={i} />);
                }

                this.errorMessage = "No communities found";
                this.communities = items;
            } catch (e) {
                console.log(e);
            }
        }
    }

    async render() {
        if (!Login.hasGroup("Admin")) return null;
        if (this.edit !== null) {
            try {
                return <CommunityEditView item={this.items[this.edit]} />;
            } catch (e) {
                return <div class="flex-fill d-flex flex-column allow-scroll-y relative" style="margin-top: 64px;" />;
            }
        } else {
            let content = [
                <ListView noItemsMessage={this.errorMessage} items={this.communities} />,
                <MyndFloatingButton icon="fas fa-plus" onclick={this.onToggleAddClick} />,
            ];

            if (this.showAdd) {
                content.push(<CommunityAddView onSubmission={this.onSubmission} />);
            }

            return (
                <div class="flex-fill d-flex flex-column allow-scroll-y relative" style="margin-top: 64px;">
                    {content}
                </div>
            );
        }
    }
}
