"use strict";

import { Login } from "./login";
import Axios from "axios";

let API_URL = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/subscriptions";

if (!window.location.hostname.startsWith("portal.")) {
    API_URL = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/subscriptions";
}

export const DEFAULT_TIME_TO_CHARGE = 120 * 1000;

export default class Subscription {
    static async paginate(sort, page, search) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(
                API_URL,
                JSON.stringify({
                    type: "get",
                    page: parseInt(page),
                    sort: sort || {},
                    search: search,
                }),
                {
                    headers: {
                        "Content-Type": "text/plain",
                        Authorization: token,
                    },
                }
            );

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return { total: 0, perPage: 100, items: [] };
            }

            return result.data;
        } catch (e) {
            console.log(e.toString());
        }

        return { total: 0, perPage: 100, items: [] };
    }

    static async ship(email, orderId) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(
                API_URL,
                JSON.stringify({
                    type: "ship",
                    email: email,
                    order: orderId,
                }),
                {
                    headers: {
                        "Content-Type": "text/plain",
                        Authorization: token,
                    },
                }
            );

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return result.data;
        } catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async status(email) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(
                API_URL,
                JSON.stringify({
                    type: "status",
                    email: email,
                }),
                {
                    headers: {
                        "Content-Type": "text/plain",
                        Authorization: token,
                    },
                }
            );

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return null;
            }

            return result.data;
        } catch (e) {
            console.log(e.toString());
        }

        return null;
    }
}
