export const INITIAL_RELEASE_DATE = new Date(2018, 1).getTime();
export const INDEX_TO_DEVICE_TYPE = ['Headset', 'Tablet'];

const UrlRegexString = "^" +
// protocol identifier (optional)
// short syntax // still required
"(?:(?:(?:https?|ftp):)?\\/\\/)" +
// user:pass BasicAuth (optional)
"(?:\\S+(?::\\S*)?@)?" +
"(?:" +
    // IP address exclusion
    // private & local networks
    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
"|" +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    "(?:" +
    "(?:" +
        "[a-z0-9\\u00a1-\\uffff]" +
        "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
    ")?" +
    "[a-z0-9\\u00a1-\\uffff]\\." +
    ")+" +
    // TLD identifier name, may end with dot
    "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
")" +
// port number (optional)
"(?::\\d{2,5})?" +
// resource path (optional)
"(?:[/?#]\\S*)?" +
"$";

const UrlRegex = new RegExp(UrlRegexString, 'i');

const getQueryString = (queryString) => {
    const query = {};

    const queryIndex = queryString.indexOf('?');

    if(queryIndex > -1) {
        queryString = queryString.substr(queryIndex);
    }

    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

const dateFieldToNumber = (str) => {
    const split = str.split('-');

    if(split.length > 2) {
        const date = new Date(parseInt(split[0]), parseInt(split[1]) - 1, parseInt(split[2]));
        return date.getTime();
    }

    return Date.now();
}

const dateNumberToDateField = (num, useNow) => {
    const now = new Date(Date.now());
    let month = now.getMonth()+1;
    let day = now.getDate();
    let year = now.getFullYear();

    if(!useNow) {
        let d = new Date(num);
        month = d.getMonth()+1;
        day = d.getDate();
        year = d.getFullYear();
    }

    if(month < 10) {
        month = '0' + month;
    }
    if(day < 10) {
        day = '0' + day;
    }
    
    return `${year}-${month}-${day}`;
}

export const SORTER = (all, dir, key) => {
    return all.sort((u1,u2) => {
        if(dir > 0) {
            if(u1[key] < u2[key]) {
                return -1;
            }
            else if(u1[key] > u2[key]) {
                return 1;
            }

            return 0;
        }
        else {
            if(u1[key] < u2[key]) {
                return 1;
            }
            else if(u1[key] > u2[key]) {
                return -1;
            }

            return 0;
        }
    });
};

export const QUERY = getQueryString(window.location.search);
export const URLREGEX = UrlRegex;
export const URLREGEXRAW = UrlRegexString;
export const GETQUERY = getQueryString;
export const DateFieldToNumber = dateFieldToNumber;
export const DateNumberToDateField = dateNumberToDateField; 