"use strict";

import VrRenderer from "../vr/renderer";
import PlayerFactory from "../vr/playerfactory";
import { MyndButton, MyndInput, FilterCategory, ListViewGrid } from "./common";
import { Littlstar } from "../logic/littlstar";
import Communities from "../logic/community";
import { INITIAL_RELEASE_DATE } from "../logic/constants";
import { Login } from "../logic/login";
import { KeywordDescriptionEditorView, KeywordReleaseDateEditorView, KeywordEditorView } from "./keywords";
import { MultiDeviceSelector } from "./devices";

const DefaultVideoFilters = [
    {
        title: "New",
        icon: "fas fa-clone",
        type: "released",
        checkbox: [
            {
                value: "30 Days Ago",
                max: 30,
                checked: false,
            },
            {
                value: "60 days ago",
                max: 60,
                checked: false,
            },
            {
                value: "120 days ago",
                max: 120,
                checked: false,
            },
        ],
    },
    {
        title: "Experience Level",
        icon: "fas fa-signal",
        type: "eq",
        checkbox: [
            {
                value: "Pilgrim",
                desc:
                    'Low energy experiences selected for seniors who have no experience with VR. Ideal for seniors desiring calm, no-thrill experiences in which the camera does not move. The Pilgrim experience level specifically excludes videos tagged with: Personal space, Distressing audio, Small spaces, Water, Spiders/Big bugs, Heights, Flying, Flashing lights. To further exclude certain videos from the Pilgrim experience level such as videos with cars or crowds, select the words from "Does Not Contain" below.',
                checked: false,
            },
            {
                value: "Explorer",
                desc:
                    'Low energy experiences selected for seniors who have experience with VR. Ideal for seniors who desire low-thrill experiences, those who can handle some movement, and are able to handle people/animals/objects within their personal space boundary. The Explorer experience level specifically excludes videos tagged with: Distressing audio, Small spaces, Water, Spiders/Big bugs, Heights, Flying, Flashing lights. To further exclude certain videos from the Explorer experience level such as videos with cars or crowds, select the words from "Does Not Contain" below.',
                checked: false,
            },
            {
                value: "Adventurer",
                desc:
                    'Low to medium energy experiences selected for seniors who have experienced VR and are interested in some thrilling activities. Includes some videos from ACTION category, but excludes videos with extreme heights, or intense first-person experiences. Ideal for seniors with VR experience who desire moderately thrilling, low-energy to medium-energy experiences and are able to handle people/animals/objects within their personal space boundary, distressing audio, small spaces, water, spiders/big bugs, and heights. The Adventure experience level specifically excludes videos tagged with: Flying, Flashing lights. To further exclude certain videos from the Explorer experience level such as videos with spiders or crowds, select the words from "Does Not Contain" below.',
                checked: false,
            },
            {
                value: "Pathfinder",
                desc:
                    'Medium to high energy experiences selected for seniors who have experience with VR and are interested in thrilling experiences. Includes videos from ACTION category, such as those with extreme heights and intense, first-person experiences. Ideal for seniors who have experience with VR, desire high-energy/high-thrill experiences like skydiving, driving fast cars, etc. Excludes videos tagged with: Pathfinder category excludes no videos. To selectively exclude certain videos from the Pathfinder experience level such as videos with spiders or small spaces, select the words from "Does Not Contain" below.',
                checked: false,
            },
        ],
    },
    {
        title: "Motion",
        icon: "fas fa-camera",
        type: "eq",
        checkbox: [
            {
                value: "Stationary",
                desc: "Does not move",
                checked: false,
            },
            {
                value: "Mostly Stationary",
                desc: "Moves less than half the duration of the video",
                checked: false,
            },
            {
                value: "Mixed",
                desc: "About half stationary, half moving",
                checked: false,
            },
            {
                value: "Moving",
                desc: "Camera moves more than half of the video or enough to cause possible cyber sickness",
                checked: false,
            },
        ],
    },
    {
        title: "Does Not Contain",
        type: "neq",
        icon: "fas fa-ban",
        checkbox: [
            {
                value: "alone",
                checked: false,
            },
            {
                value: "arachnids",
                checked: false,
            },
            {
                value: "cars",
                checked: false,
            },
            {
                value: "crowds",
                checked: false,
            },
            {
                value: "darkness",
                checked: false,
            },
            {
                value: "distressing audio",
                checked: false,
            },
            {
                value: "fire",
                checked: false,
            },
            {
                value: "flashing lights",
                checked: false,
            },
            {
                value: "flying",
                checked: false,
            },
            {
                value: "heights",
                checked: false,
            },
            {
                value: "interactive",
                checked: false,
            },
            {
                value: "isolation",
                checked: false,
            },
            {
                value: "lightning",
                checked: false,
            },
            {
                value: "open spaces",
                checked: false,
            },
            {
                value: "personal space",
                checked: false,
            },
            {
                value: "public transit",
                checked: false,
            },
            {
                value: "scorpions",
                checked: false,
            },
            {
                value: "shopping center",
                checked: false,
            },
            {
                value: "small spaces",
                checked: false,
            },
            {
                value: "snakes",
                checked: false,
            },
            {
                value: "spiders",
                checked: false,
            },
            {
                value: "thunder",
                checked: false,
            },
            {
                value: "trucks",
                checked: false,
            },
            {
                value: "underwater",
                checked: false,
            },
            {
                value: "vans",
                checked: false,
            },
            {
                value: "water",
                checked: false,
            },
        ],
    },
    {
        title: "Seasons",
        icon: "fas fa-snowflake",
        type: "eq",
        checkbox: [
            {
                value: "Spring",
                desc: null,
                checked: false,
            },
            {
                value: "Summer",
                desc: null,
                checked: false,
            },
            {
                value: "Fall",
                desc: null,
                checked: false,
            },
            {
                value: "Winter",
                desc: null,
                checked: false,
            },
        ],
    },
    {
        title: "Video Duration",
        icon: "fas fa-stopwatch",
        type: "time",
        checkbox: [
            {
                value: "Max 1 minute",
                desc: null,
                checked: false,
                max: 1,
            },
            {
                value: "Max 2 minutes",
                desc: null,
                checked: false,
                max: 2,
            },
            {
                value: "Max 3 minutes",
                desc: null,
                checked: false,
                max: 3,
            },
            {
                value: "Max 4 minutes",
                desc: null,
                checked: false,
                max: 4,
            },
            {
                value: "Max 5 minutes",
                desc: null,
                checked: false,
                max: 5,
            },
            {
                value: "Max 6 minutes",
                desc: null,
                checked: false,
                max: 6,
            },
            {
                value: "Max 7 minutes",
                desc: null,
                checked: false,
                max: 7,
            },
            {
                value: "Max 8 minutes",
                desc: null,
                checked: false,
                max: 8,
            },
            {
                value: "Max 9 minutes",
                desc: null,
                checked: false,
                max: 9,
            },
            {
                value: "Max 10 minutes",
                desc: null,
                checked: false,
                max: 10,
            },
            {
                value: "Greater than 10 minutes",
                desc: null,
                checked: false,
                min: 10,
            },
        ],
    },
];

let SelectedVideoFilters = [];

class VideoPlayer extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.observe({ position: 0, isBuffering: false, isPaused: false, toastMessage: null });
        this.duration = 1;
        this.onClosePlayer = this.onClosePlayer.bind(this);
        this.onHashChange = this.onHashChange.bind(this);
        this.onError = this.onError.bind(this);
        this.onProgressClick = this.onProgressClick.bind(this);
        this.onVideoFrameUpdate = this.onVideoFrameUpdate.bind(this);
        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);

        this.onReady = this.onReady.bind(this);
        this.onBuffer = this.onBuffer.bind(this);
        this.playPause = this.playPause.bind(this);

        this.progressBar = null;
        this.videoElement = null;
        this.toastMessage = null;
    }

    onError(e) {
        this.toastMessage = "There was an error in the video stream. Please relaunch the video.";
    }

    onHashChange(e) {
        if (this.video) {
            this.view.video = null;
        }
    }

    onClosePlayer(e) {
        e.preventDefault();
        e.stopPropagation();

        console.log("on video view close");

        const idx = location.hash.indexOf("&video=");

        if (idx > -1) {
            location.hash = location.hash.substring(0, idx);
        } else {
            if (this.video) {
                this.view.video = null;
            }
        }
    }

    showToast(msg) {
        this.toastMessage = msg;

        setTimeout(() => {
            this.toastMessage = null;
        }, 2000);
    }

    async onCopyToClipboard() {
        if (!this.video) return;
        try {
            await navigator.clipboard.writeText(
                `${location.protocol}//${location.hostname}${location.port ? ":" + location.port : ""}/#/videos?category=${
                    this.video.category
                }&video=${this.video.id}`
            );
            this.showToast("Video url copied to clipboard");
        } catch (e) {
            this.showToast("Could not copy video url to clipboard");
        }
    }

    onVideoFrameUpdate(e) {
        this.position = this.videoElement.currentTime;
    }

    onBuffer() {
        this.isBuffering = true;
    }

    onReady() {
        this.isBuffering = false;
        this.isPaused = false;
    }

    playPause() {
        if (this.isPaused) {
            this.videoElement.play();
        } else {
            this.videoElement.pause();
            this.isPaused = true;
        }
    }

    timeToText(time) {
        let min = Math.round(time / 60);
        let secs = Math.round(time % 60);

        if (min < 10) {
            min = "0" + min;
        }

        if (secs < 10) {
            secs = "0" + secs;
        }

        return min + ":" + secs;
    }

    onProgressClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const rect = this.progressBar.getBoundingClientRect();
        const x = e.clientX - rect.left; //x position within the element.
        const y = e.clientY - rect.top; //y position within the element.

        const width = rect.right - rect.left;
        const percent = x / width;

        console.log("percent: " + Math.round(percent * 100) + " duration: " + this.duration * percent);

        this.videoElement.pause();
        this.videoElement.currentTime = Math.round(this.duration * percent);
        this.videoElement.play();
    }

    async componentDidMount() {
        window.addEventListener("hashchange", this.onHashChange, false);
        this.progressBar.addEventListener("click", this.onProgressClick, false);
        this.videoElement.addEventListener("timeupdate", this.onVideoFrameUpdate, false);

        ["waiting", "stalled", "seeking", "suspend"].forEach((m) => this.videoElement.addEventListener(m, this.onBuffer, false));
        this.videoElement.addEventListener("playing", this.onReady, false);

        setTimeout(() => {
            this.getStreamsAndLoad();
        }, 1);
    }

    async componentWillUnmount() {
        this.renderer.dispose();
        this.player = null;
        this.progressBar.removeEventListener("click", this.onProgressClick, false);
        this.videoElement.removeEventListener("timeupdate", this.onVideoFrameUpdate, false);

        ["waiting", "stalled", "seeking", "suspend"].forEach((m) => this.videoElement.removeEventListener(m, this.onBuffer, false));
        this.videoElement.removeEventListener("playing", this.onReady, false);

        window.removeEventListener("hashchange", this.onHashChange, false);
    }

    async getStreamsAndLoad() {
        try {
            this.duration = this.video.duration;
            const streams = await Littlstar.getStreams(this.video.category, this.video.id);
            this.renderer = new VrRenderer(this.videoElement);
            this.renderer.init();
            PlayerFactory.load(this.videoElement, this.getBestStream(streams));

            setTimeout(async () => {
                try {
                    const promise = this.videoElement.play();

                    if (promise) {
                        await promise;
                    }
                } catch (e) {
                    console.log(e);
                    this.isPaused = true;
                    this.isBuffering = false;
                }
            }, 250);
        } catch (e) {
            console.log(e);
        }
    }

    findStream(streams, type, kind) {
        for (let i = 0; i < streams.length; i++) {
            const stream = streams[i];

            if (stream.name.toLowerCase() == type) {
                return stream;
            } else if (stream.kind.toLowerCase() == kind) {
                return stream;
            }
        }

        return null;
    }

    getBestStream(streams) {
        let stream = this.findStream(streams, "web_dash");
        if (!stream) {
            stream = this.findStream(streams, null, "dash");
        }
        if (!stream) {
            stream = this.findStream(streams, "mobile_hls");
        }
        if (!stream) {
            stream = this.findStream(streams, null, "hls");
        }

        if (!stream) {
            console.log("couldnt find best stream, using first stream...");
            stream = streams[0];
        }

        return stream.url;
    }

    async render() {
        return (
            <div
                class={"bg-dark " + (this.video ? "" : "hidden")}
                style="position: fixed; top: 64px; left: 0px; right: 0px; bottom: 0px; z-index: 100; overflow:hidden;">
                <video
                    ref={(f) => (this.videoElement = f)}
                    class="mynd-video-player"
                    preload="auto"
                    onended={this.onClosePlayer}
                    onerror={this.onError}
                />
                <MyndButton class="abs-tr mr-4 mt-4" onclick={this.onClosePlayer} style="z-index:1000; width: 125px; height: 64px; font-size: 16pt;">
                    CLOSE
                </MyndButton>
                <MyndButton class="abs ml-4 mt-4" onclick={this.onCopyToClipboard} style="z-index:10000;">
                    <i class="far fa-share-square" style="font-size: 22pt;"></i>
                </MyndButton>
                <h2 class={"abs-bl bg-dark text-white p-4 " + (this.toastMessage ? "" : "hidden")} style="z-index: 200; bottom: 25%;">
                    {this.toastMessage}
                </h2>
                <div class="abs-bl w-100 d-flex align-items-center justify-content-center" style="z-index: 200; bottom: 5%;">
                    <div onclick={this.playPause} class="p-4 pointer hoverable text-white">
                        <i class={"far " + (this.isPaused ? "fa-play-circle" : "fa-pause-circle")} style="font-size: 24pt;"></i>
                    </div>
                    <div class="progress w-50" style="left: 15%; height: 8px;" ref={(f) => (this.progressBar = f)}>
                        <div class="progress-bar" style={`width: ${Math.round((this.position / this.duration) * 100)}%`}></div>
                    </div>
                    <div class="p-4 text-white">{this.timeToText(this.position) + " / " + this.timeToText(this.duration)}</div>
                </div>
                <div
                    class={"spin text-white " + (this.isBuffering ? "" : "hidden")}
                    style="position: absolute; top: 45%; left: 45%; font-size: 42pt; z-index: 201;">
                    <i class="fas fa-spinner"></i>
                </div>
            </div>
        );
    }
}

export class VideoListItem extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.observe({
            showSaving: false,
            showKeywordEdit: false,
            showDescriptionEdit: false,
            showReleaseDate: false,
            showAddTo: false,
            isVisible: false,
            image: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
        });
        this.onWatchClick = this.onWatchClick.bind(this);
        this.onAddToClick = this.onAddToClick.bind(this);
        this.onKeywordsClick = this.onKeywordsClick.bind(this);
        this.onDevicesSelected = this.onDevicesSelected.bind(this);
        this.onDescriptionClick = this.onDescriptionClick.bind(this);
        this.onReleaseDateClick = this.onReleaseDateClick.bind(this);

        this.onScroll = this.onScroll.bind(this);
        this.div = null;
    }

    onDescriptionClick(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!this.showDescriptionEdit) {
            this.showDescriptionEdit = true;
        } else {
            this.showDescriptionEdit = false;
        }
    }

    onKeywordsClick(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!this.showKeywordEdit) {
            this.showKeywordEdit = true;
        } else {
            this.showKeywordEdit = false;
        }
    }

    onReleaseDateClick(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!this.showReleaseDate) {
            this.showReleaseDate = true;
        } else {
            this.showReleaseDate = false;
        }
    }

    onAddToClick(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!this.showAddTo) {
            this.disabled = true;
            this.showSaving = false;
            this.disabled = false;

            this.showAddTo = true;
        } else {
            this.showAddTo = false;
        }
    }

    async onDevicesSelected(devices) {
        this.showSaving = true;

        for (let i = 0; i < devices.length; i++) {
            const d = devices[i];
            try {
                await d.addDownload(this.video.slug);
            } catch (e) {
                console.log(e);
            }
        }

        this.disabled = true;
        this.showSaving = false;
        this.disabled = false;

        this.showAddTo = false;
    }

    onWatchClick(e) {
        e.preventDefault();
        e.stopPropagation();

        this.view.video = this.video;
    }

    get keywords() {
        return this.video.keywords;
    }

    get imageBanner() {
        const banners = this.video.banners;
        const posters = this.video.posters;

        if (banners == null) {
            return posters.mobile;
        } else {
            if (banners.small) {
                return banners.small;
            } else if (banners.medium) {
                return banners.medium;
            } else {
                return posters.mobile;
            }
        }
    }

    get imageBannerSmall() {
        const banners = this.video.banners;
        const posters = this.video.posters;

        if (banners == null) {
            return posters.mobile;
        } else {
            if (banners.small) {
                return banners.small;
            } else {
                return posters.mobile;
            }
        }
    }

    get experienceLevel() {
        let tags = [];

        if (this.video.tags.length > 0) {
            tags = this.video.tags;
        } else {
            tags = this.keywords;
        }

        let xpLevel = "Pilgrim";

        if (tags.indexOf("explorer") > -1) {
            xpLevel = "Explorer";
        } else if (tags.indexOf("adventurer") > -1) {
            xpLevel = "Adventurer";
        } else if (tags.indexOf("pathfinder") > -1) {
            xpLevel = "Pathfinder";
        }

        return xpLevel;
    }

    get duration() {
        const min = Math.floor(this.video.duration / 60);
        const secs = Math.floor(this.video.duration % 60);

        let timeText = "";

        if (min < 10) {
            timeText += "0" + min;
        } else {
            timeText += min;
        }

        timeText += ":";

        if (secs < 10) {
            timeText += "0" + secs;
        } else {
            timeText += secs;
        }

        return timeText;
    }

    get description() {
        if (this.video.myndDescription) {
            return this.video.myndDescription;
        }

        return this.video.description;
    }

    onScroll(e) {
        if (!this.isVisible) {
            if (this.isInViewport()) {
                this.isVisible = true;

                if (this.view && this.view.base) {
                    this.view.base.removeEventListener("scroll", this.onScroll, false);
                }

                window.removeEventListener("resize", this.onScroll, false);
            }
        }
    }

    isInViewport() {
        if (!this.div) return false;

        var rect = this.div.getBoundingClientRect();

        return (
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    async componentDidMount() {
        if (this.view && this.view.base) {
            this.view.base.addEventListener("scroll", this.onScroll, false);
        }

        window.addEventListener("resize", this.onScroll, false);
        setTimeout(() => {
            this.onScroll();
        }, 1);
    }

    async componentWillUnmount() {
        if (this.view && this.view.base) {
            this.view.base.removeEventListener("scroll", this.onScroll, false);
        }
        window.removeEventListener("resize", this.onScroll, false);
    }

    async render() {
        let tags = [];

        if (this.video.tags.length > 0) {
            tags = this.video.tags;
        } else {
            tags = this.keywords;
        }

        this.isHeight = tags.indexOf("heights") > -1;

        const editButtons = [];

        editButtons.push(<MyndButton onclick={this.onAddToClick} class="ml-auto" text="ADD TO DEVICES" />);

        if (Login.hasGroup("Admin")) {
            editButtons.splice(0, 0, <MyndButton class="ml-2 mr-auto" onclick={this.onReleaseDateClick} text="RELEASE DATE" />);
            editButtons.splice(0, 0, <MyndButton class="ml-2 mr-auto" onclick={this.onKeywordsClick} text="EDIT KEYWORDS" />);
            editButtons.splice(
                0,
                0,
                <MyndButton type="light" class="mr-auto round-button mr-2 text-dark material-shadow" onclick={this.onDescriptionClick}>
                    <i class="fas fa-pencil-alt" style="font-size: 10pt;"></i>
                </MyndButton>
            );
            editButtons.splice(
                0,
                0,
                <p class="mr-2" style="margin-bottom: 0; padding: 0;">
                    SDCard ID: {this.video.slug}
                </p>
            );
        }

        let title = this.video.title;

        if (this.video.myndTitle && this.video.myndTitle.length > 0) {
            title = this.video.myndTitle;
        }

        let xpLevel = "Pilgrim";
        let motion = "Stationary";

        const isFlying = tags.indexOf("flying") > -1;
        const isSmallSpace = tags.indexOf("small spaces") > -1;
        const isPersonalSpace = tags.indexOf("personal space") > -1;
        const isInteractive = tags.indexOf("interactive") > -1;
        const isDistressingAudio = tags.indexOf("distressing audio") > -1;
        const isFlashingLights = tags.indexOf("flashing lights") > -1;
        const isWater = tags.indexOf("water") > -1;
        const isSpiders = tags.indexOf("spiders") > -1 || tags.indexOf("big bugs") > -1;

        if (tags.indexOf("mostly stationary") > -1) {
            motion = "Mostly Stationary";
        } else if (tags.indexOf("mixed") > -1) {
            motion = "Mixed";
        } else if (tags.indexOf("moving") > -1) {
            motion = "Moving";
        }

        xpLevel = this.experienceLevel;

        const content = [
            <h4>{Littlstar.getCatById(this.video.category).name}</h4>,
            <h3>{title}</h3>,
            <div class="d-flex flex-column">
                <p cclass="abs-tr" style="right: 45px; top: 25px;">
                    <strong>Duration:</strong> {this.duration}
                </p>
                <p>{this.description}</p>
                <div class="d-flex flex-row flex-wrap">
                    <small class="mt-1 ml-4">
                        <strong>Experience Level:</strong> {xpLevel}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Interactive:</strong> {isInteractive ? "Yes" : "No"}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Motion:</strong> {motion}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Heights:</strong> {this.isHeight ? "Yes" : "No"}
                        <i
                            class="fas fa-exclamation-triangle height-warning ml-2"
                            alt="This video contains heights"
                            title="This video contains heights"
                            style={this.isHeight ? "display: inline-block" : "display: none;"}></i>
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Flashing Lights:</strong> {isFlashingLights ? "Yes" : "No"}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Personal Space:</strong> {isPersonalSpace ? "Yes" : "No"}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Small Spaces:</strong> {isSmallSpace ? "Yes" : "No"}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Water / Underwater:</strong> {isWater ? "Yes" : "No"}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Spiders / Big bugs:</strong> {isSpiders ? "Yes" : "No"}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Distressing Audio:</strong> {isDistressingAudio ? "Yes" : "No"}
                    </small>
                    <small class="mt-1 ml-4">
                        <strong>Flying:</strong> {isFlying ? "Yes" : "No"}
                    </small>
                </div>
            </div>,
            <div class="d-flex flex-wrap align-items-center mt-2">
                <p class="text-wrap" style="font-size: 9pt;">
                    {tags.join(", ")}
                </p>
            </div>,
            <div class="d-flex flex-wrap flex-row mt-auto">{editButtons}</div>,
        ];

        if (this.showAddTo) {
            content.push(
                <MultiDeviceSelector
                    saving={this.showSaving}
                    onSelect={this.onDevicesSelected}
                    oncancel={this.onAddToClick}
                    submitTitle="ADD"
                    cancelTitle="CANCEL"
                />
            );
        }

        if (this.showKeywordEdit && Login.hasGroup("Admin")) {
            content.push(<KeywordEditorView video={this.video} onclose={this.onKeywordsClick} submitTitle="APPLY" cancelTitle="CANCEL" />);
        }

        if (this.showDescriptionEdit && Login.hasGroup("Admin")) {
            content.push(
                <KeywordDescriptionEditorView video={this.video} onclose={this.onDescriptionClick} submitTitle="APPLY" cancelTitle="CANCEL" />
            );
        }

        if (this.showReleaseDate && Login.hasGroup("Admin")) {
            content.push(
                <KeywordReleaseDateEditorView video={this.video} onclose={this.onReleaseDateClick} submitTitle="APPLY" cancelTitle="CANCEL" />
            );
        }

        const imageCard = (
            <div
                ref={(f) => (this.div = f)}
                class="video-image mr-1"
                style={`background-image: url("${this.isVisible ? this.imageBanner : this.image}");`}>
                <div class="d-flex flex-column video-image-info justify-content-end" style="height: 100%">
                    <div class="relative mt-2">
                        <MyndButton onclick={this.onWatchClick} type="light w-100" style="height: 64px;">
                            WATCH
                        </MyndButton>
                    </div>
                </div>
            </div>
        );

        return (
            <div class="d-flex video-item ml-auto mr-auto">
                <div class="d-flex flex-row flex-fill">
                    {imageCard}
                    <div class="d-flex flex-fill video-info flex-column text-white p-4">{content}</div>
                </div>
            </div>
        );
    }
}

class CategoryListItem extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({ image: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" });

        this.onClick = this.onClick.bind(this);
        this.onScroll = this.onScroll.bind(this);

        this.div = null;
        this.isVisible = false;
    }

    onScroll(e) {
        if (!this.isVisible) {
            if (this.isInViewport()) {
                this.isVisible = true;
                this.loadImage();

                this.view.base.removeEventListener("scroll", this.onScroll, false);

                window.removeEventListener("resize", this.onScroll, false);
            }
        }
    }

    isInViewport() {
        if (!this.div) return false;

        var rect = this.div.getBoundingClientRect();

        return (
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    onClick(e) {
        e.stopPropagation();

        this.view.base.scrollTop = 0;
        window.location.hash = "#/videos?category=" + this.category.id;
    }

    async componentDidMount() {
        this.view.base.addEventListener("scroll", this.onScroll, false);
        window.addEventListener("resize", this.onScroll, false);

        setTimeout(() => {
            this.onScroll();
        }, 1);
    }

    async componentWillUnmount() {
        if (this.view.base) {
            this.view.base.removeEventListener("scroll", this.onScroll, false);
        }
        window.removeEventListener("resize", this.onScroll, false);
    }

    async loadImage() {
        try {
            let activationDate = null;
            try {
                activationDate = await Communities.activationDate;
            } catch (e) {
                console.log(e);
                activationDate = INITIAL_RELEASE_DATE;
            }

            const vids = await Littlstar.getVideos(this.category.id, null, activationDate);
            if (vids.videos.length > 0) {
                const vitem = new VideoListItem({ video: vids.videos[0] });
                this.image = vitem.imageBanner;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async render() {
        return (
            <div
                ref={(f) => (this.div = f)}
                class="video-category relative pointer material-shadow ml-2 mr-2"
                style={`background-image: url("${this.image}");`}
                onclick={this.onClick}>
                <div class="d-flex flex-column justify-content-end category-info text-white" style="height: 100%;">
                    <h1 class="display-1">{this.category.name}</h1>
                </div>
            </div>
        );
    }
}

class VideoSearch extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({ search: "" });
        this.onSubmit = this.onSubmit.bind(this);
        this.onInput = this.onInput.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.setRef = this.setRef.bind(this);
        this.onHashChange = this.onHashChange.bind(this);
        this.waitTimer = null;
        this.input = null;
        this.title = null;
        this.backButton = null;
    }

    onHashChange(e) {
        setTimeout(() => {
            if (this.backButton) {
                if (this.category) {
                    this.backButton.classList.remove("hidden");
                } else {
                    if (!this.backButton.classList.contains("hidden")) {
                        this.backButton.classList.add("hidden");
                    }
                }
            }

            if (this.title) {
                if (this.category) {
                    this.title.classList.remove("hidden");
                } else {
                    if (!this.title.classList.contains("hidden")) {
                        this.title.classList.add("hidden");
                    }
                }
            }

            if (this.input && this.input.clear) {
                this.input.clear();
            }
        }, 1);
    }

    setRef(f) {
        this.input = f;
    }

    onClear() {
        this.query("");
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.onSubmit(e);
        }
    }

    onInput(e) {
        this.query(e.target.value);
    }

    query(value) {
        if (this.waitTimer) {
            clearTimeout(this.waitTimer);
        }

        this.search = value;

        if (value && value.length > 0) {
            this.waitTimer = setTimeout(() => {
                this.view.search = this.search;
            }, 500);
        } else {
            this.view.search = null;
        }
    }

    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.query(e.target.value);
    }

    async componentDidMount() {
        window.addEventListener("hashchange", this.onHashChange, false);
    }

    async componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange, false);
    }

    async render() {
        if (!Littlstar.categoryIdCache) {
            await Littlstar.categories;
        }

        const cat = this.category ? Littlstar.getCatById(this.category) : null;

        const catName = cat ? cat.name : "";

        return (
            <div
                class={
                    "video-search fixed-header material-shadow text-white flex-fill bg-dark d-flex relative align-items-center justify-content-center " +
                    (cat ? "extended" : "")
                }>
                <div class="d-flex flex-row align-items-center">
                    <a
                        ref={(f) => (this.backButton = f)}
                        href="#/videos"
                        class={"pointer d-flex flex-row align-items-center mr-5" + (this.category ? "" : " hidden")}>
                        <i class="fas fa-chevron-left mt-1" style="font-size: 2rem;"></i>
                        <p class="display-10 ml-3" style="margin: 0px;">
                            Categories
                        </p>
                    </a>
                    <h1 ref={(f) => (this.title = f)} class={"display-6 mr-5" + (this.category ? "" : " hidden")}>
                        {catName}
                    </h1>
                </div>
                <MyndInput
                    type="text"
                    titleClass="text-material-light"
                    class="material-input-light"
                    placeholder="search"
                    style="padding-bottom: 1rem;"
                    ref={this.setRef}
                    onkeypress={this.onKeyPress}
                    oninput={this.onInput}
                    handleOnClear={this.onClear}
                />
                <div class="d-flex flex-row align-items-center">
                    <a
                        alt="Printable view of all available videos to you"
                        title="Printable view of all available videos to you"
                        href="/#/print/videos"
                        target="_blank">
                        <i class="fas fa-print" style="font-size: 24pt;"></i>
                    </a>
                    <a href="#/bulk/videos" style="margin-left: 1rem;" class={Login.hasGroup("Admin") ? "" : "hidden"}>
                        Bulk Video Update
                    </a>
                </div>
            </div>
        );
    }
}

class VideoSearchResults extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({ items: [] });
        this.infoMessage = "Searching...";
    }

    async componentDidMount() {
        this.updateItems();
    }

    async componentDidUpdate(previous) {
        if (previous["filters"] || previous["keyword"] !== null) {
            this.updateItems();
        }
    }

    async updateItems() {
        try {
            let results = await Littlstar.search(this.keyword);
            results = Littlstar.filter(results, this.filters);
            const list = [];

            let key = 0;
            results.forEach((v) => {
                list.push(<VideoListItem key={`video-${key}`} video={v} view={this.view} key={v.slug} />);
                ++key;
            });

            this.infoMessage = "Nothing found";
            this.items = list;
        } catch (e) {
            console.log(e);
        }
    }

    async render() {
        return <ListViewGrid items={this.items} noItemsMessage={this.infoMessage} />;
    }
}

class Categories extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.observe({ items: [] });

        this.infoMessage = "Loading";
    }

    async componentDidMount() {
        this.updateItems();
    }

    async updateItems() {
        try {
            const results = await Littlstar.categories;
            const list = [];

            let key = 0;
            results.forEach((v) => {
                list.push(<CategoryListItem key={`category-${key}`} category={v} view={this.view} key={v.id} />);
                ++key;
            });

            this.infoMessage = "No categories found";
            this.items = list;
        } catch (e) {
            console.log(e);
        }
    }

    async render() {
        return <ListViewGrid items={this.items} noItemsMessage={this.infoMessage} />;
    }
}

class VideoFiltersView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onChange = this.onChange.bind(this);

        this.selected = {};
    }

    onChange(category, filters) {
        this.selected[category.title] = filters;

        SelectedVideoFilters = [];

        for (let n in this.selected) {
            const cat = this.selected[n];

            cat.forEach((f) => {
                SelectedVideoFilters.push(f);
            });
        }

        if (this.onchange) {
            this.onchange();
        }
    }

    async render() {
        const categories = [];

        let key = 0;
        DefaultVideoFilters.forEach((f) => {
            categories.push(<FilterCategory key={`filter-${key}`} category={f} onchange={this.onChange} />);
            ++key;
        });

        return <div class="video-filters m-2 p-2 bg-dark text-white d-flex flex-column">{categories}</div>;
    }
}

class Videos extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({ items: [] });
        this.page = null;
        this.hasNextPage = true;
        this.infoMessage = "Loading";
        this.onNext = this.onNext.bind(this);
    }

    async componentDidMount() {
        this.onNext();
    }

    async componentDidUpdate(previous) {
        if (previous["filters"]) {
            this.page = null;
            this.hasNextPage = true;

            this.infoMessage = "Loading";
            this.disabled = true;
            this.items = [];
            this.disabled = false;

            setTimeout(() => {
                this.onNext();
            }, 1);
        }
    }

    async onNext() {
        if (!this.hasNextPage) {
            return;
        }

        try {
            let activationDate = null;
            try {
                activationDate = await Communities.activationDate;
            } catch (e) {
                activationDate = INITIAL_RELEASE_DATE;
            }

            let vids = null;
            if (this.page != null) {
                vids = await Littlstar.getVideos(this.category, this.page, activationDate);
            } else {
                vids = await Littlstar.getVideos(this.category, null, activationDate);
            }

            vids.videos = Littlstar.filter(vids.videos, this.filters);

            this.page = vids.next;
            this.hasNextPage = vids.next != null;

            const list = [];

            let playVid = null;

            let key = 0;
            vids.videos.forEach((v) => {
                if (this.vid === v.id && this.view) {
                    playVid = v;
                }

                list.push(<VideoListItem key={`video-${key}`} video={v} view={this.view} key={v.slug} />);
                ++key;
            });

            this.infoMessage = "No videos founds";
            this.items = this.items.concat(list);

            if (playVid !== null) {
                setTimeout(() => {
                    if (this.view) {
                        this.view.video = playVid;
                    }
                }, 10);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async render() {
        return <ListViewGrid items={this.items} onNext={this.onNext} noItemsMessage={this.infoMessage} />;
    }
}

export default class VideosList extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({ video: null });
        this.onFilterChanges = this.onFilterChanges.bind(this);
    }

    onFilterChanges() {
        this.forceUpdate();
    }

    onBack(e) {
        e.preventDefault();
        e.stopPropagation();

        history.back();
    }

    async render() {
        let view = null;

        if (this.search) {
            view = <VideoSearchResults key="view" keyword={this.search} category={this.category} view={this} filters={SelectedVideoFilters} />;
        } else if (this.category) {
            view = <Videos category={this.category} vid={this.vid} key="view" view={this} filters={SelectedVideoFilters} />;
        } else if (!this.category && !this.search && SelectedVideoFilters.length > 0) {
            view = <VideoSearchResults key="view" keyword={null} category={null} view={this} filters={SelectedVideoFilters} />;
        } else {
            view = <Categories view={this} key="view" />;
        }

        const content = [<VideoFiltersView key="filters" onchange={this.onFilterChanges} />, view];

        if (this.video) {
            content.splice(0, 0, <VideoPlayer key="video" view={this} video={this.video} />);
        }

        return (
            <div class="flex-fill d-flex flex-column allow-scroll-y relative" style="margin-top: 64px;">
                <VideoSearch key="videosearch" view={this} category={this.category} />
                <div class="videos w-100 flex-fill d-flex relative">{content}</div>
            </div>
        );
    }
}
