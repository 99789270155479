'use strict';

export default class PlayerFactory {
    static load(videoElement, url) {
        if(url.endsWith('.mpd')) {
            return PlayerFactory.getDashPlayer(videoElement, url);
        }
        else if(url.endsWith('.m3u8')) {
            return PlayerFactory.getHlsPlayer(videoElement, url);
        }
        else {
            return PlayerFactory.getNative(videoElement, url);
        }
    }

    static getNative(videoElement, url) {
        videoElement.setAttribute('src', url);
        
        videoElement.onload = () => {
            videoElement.play();
        };

        videoElement.load();

        return videoElement;
    }

    static getHlsPlayer(videoElement, url) {
        var hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(videoElement);
        return hls;
    }

    static getDashPlayer(videoElement, url) {
        const player = dashjs.MediaPlayer().create();
        player.initialize(videoElement, url, true);
        return player;
    }
}