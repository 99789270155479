"use strict";

import { MyndButton, MyndInput, Spinner } from "./common";
import { Login } from "../logic/login";
import { SigninTwoFactor } from "./signin";

class TwoFactorStepOne extends Component {
    async render() {
        return (
            <div class="d-flex flex-fill align-items-center flex-column justify-content-center">
                <h1 class="display-1">Two-Factor Setup</h1>
                <div class="min-width-512 p-4 d-flex flex-column bg-white material-shadow">
                    <p>We now require all users to use two-factor authentication</p>
                    <p>
                        You will need either Google Authenticator or Authy.
                        <br />
                    </p>
                    <p class="text-center">
                        <a
                            href="https://apps.apple.com/us/app/authy/id494168017"
                            class="p-2 bg-light rounded m-2 material-shadow inline-block text-center"
                            target="_blank"
                            style="width: 200px; text-decoration: none;">
                            <i class="fab fa-apple" style="font-size: 2rem; margin-right: 1rem;"></i>
                            <img
                                src="https://is2-ssl.mzstatic.com/image/thumb/Purple123/v4/cf/95/a8/cf95a89d-2f2a-4193-2a99-07e1207fd11a/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-85-220.png/246x0w.png"
                                width="32"
                                height="32"
                                class="mr-2 rounded"
                            />
                            Authy
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.authy.authy"
                            class="p-2 bg-light rounded m-2 material-shadow inline-block text-center"
                            target="_blank"
                            style="width: 200px; text-decoration: none;">
                            <i class="fab fa-android" style="font-size: 2rem; margin-right: 1rem;"></i>
                            <img
                                src="https://is2-ssl.mzstatic.com/image/thumb/Purple123/v4/cf/95/a8/cf95a89d-2f2a-4193-2a99-07e1207fd11a/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-85-220.png/246x0w.png"
                                width="32"
                                height="32"
                                class="mr-2 rounded"
                            />
                            Authy
                        </a>
                    </p>
                    <p>After you have the app on your mobile device, press next to get started</p>
                    <MyndButton onclick={this.onclick} style={"width: 100%" + (this.loading ? "display: none;" : "")}>
                        NEXT
                    </MyndButton>
                </div>
            </div>
        );
    }
}

class TwoFactorStepTwo extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.submit = this.submit.bind(this);
    }

    submit(e) {
        if (this.verify) {
            this.verify(e);
        }
    }

    async componentDidMount() {
        const url = `otpauth://totp/portal.myndvr.com:${Login.email}?secret=${this.secret}&issuer=portal.myndvr.com`;
        new QRCode(this.qr, url);
    }

    async render() {
        return (
            <div class="d-flex flex-fill align-items-center flex-column justify-content-center">
                <h1 class="display-1">Two-Factor Setup</h1>
                <div class="min-width-512 p-4 d-flex flex-column bg-white material-shadow align-items-center">
                    <p>
                        Either scan the QR Code or setup manually with secret: <br />
                        <strong>{this.secret}</strong>
                    </p>
                    <div ref={(f) => (this.qr = f)}></div>
                    <p style="margin-top: 1rem;">Then enter the current one time password in the field below and press verify</p>
                    <p>Upon successful verification you will be logged out from all devices.</p>
                    {!this.loading ? <SigninTwoFactor onHandleSubmit={this.submit} /> : <Spinner />}
                </div>
            </div>
        );
    }
}

export default class TwoFactor extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            loading: false,
            stage: 0,
        });
        this.forceError = null;
        this.secret = null;
        this.next = this.next.bind(this);
    }

    async verify(tk) {
        this.loading = true;
        try {
            const success = await Login.verifySoftwareToken(tk);
            await Login.enableMFA();
            Login.globalSignout();
        } catch (e) {
            this.forceError = true;
            console.log(e);
        }
        this.loading = false;
    }

    async next() {
        this.loading = true;
        if (this.stage === 0) {
            try {
                this.disabled = true;
                this.secret = await Login.associateSoftwareToken();
                this.stage = 1;
                this.disabled = false;
            } catch (e) {
                console.log(e);
            }
        }
        this.loading = false;
    }

    async render() {
        if (this.stage === 0) {
            return (
                <div class="d-flex flex-fill align-items-center flex-column justify-content-center">
                    <TwoFactorStepOne loading={this.loading || null} onclick={this.next} key="view" />
                </div>
            );
        } else if (this.stage === 1) {
            return (
                <div class="d-flex flex-fill align-items-center flex-column justify-content-center">
                    <TwoFactorStepTwo
                        secret={this.secret}
                        forceError={this.forceError}
                        loading={this.loading || null}
                        verify={this.verify}
                        key="view"
                    />
                </div>
            );
        }
    }
}
