'use strict';

import Axios from "axios";
import {Login} from './login';

let API_URL_INTEGRATION = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/integration"

export default class Integration {
    static async get(name) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_INTEGRATION, JSON.stringify({
                name: name, 
                type: 'get'
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return null;
            }

            return result.data;
        }
        catch (e) {
            console.log(e.toString());
        }

        return null;
    }

    static async update(name, data) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_INTEGRATION, JSON.stringify({
                name: name, 
                data: data, 
                type: 'update'
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }
}