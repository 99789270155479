"use strict";

import { MyndInput, MyndButton, MyndPage } from "./common";
import { Login } from "../logic/login";

class SigninWelcome extends Component {
    async render() {
        return (
            <div class="text-center relative">
                <div class="abs pointer text-myndvr p-2" onclick={this.onclick}>
                    <i class="fas fa-chevron-left" style="font-size: 18pt;"></i>
                </div>
                <div class="display-10">Welcome</div>
                <div>{this.email}</div>
                <div class="text-muted mt-4">
                    <i class="fas fa-user-circle" style="font-size: 32pt;"></i>
                </div>
            </div>
        );
    }
}

class SigninEmail extends Component {
    constructor(props, parent) {
        super(props, parent);
        this.currentEmail = "";
        this.onInput = this.onInput.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.form = null;
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.form && this.form.checkValidity()) {
            this.onHandleSubmit(this.currentEmail);
        }
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.onSubmit(e);
        }
    }

    onInput(e) {
        this.currentEmail = e.target.value;
    }

    async render() {
        return (
            <form ref={(f) => (this.form = f)} class="min-width-512 max-width-512" id="signinForm" novalidate={true}>
                <div class="form-group text-right">
                    <MyndInput
                        type="email"
                        id="signinEmail"
                        pattern={`^[A-Za-z0-9._%+-]{1,}@[A-Za-z0-9.-]{1,}[.][A-Za-z]{2,}$`}
                        required={true}
                        placeholder="email"
                        oninput={this.onInput}
                        onkeypress={this.onKeyPress}
                        value={this.email}
                        error="Please enter an email"
                    />
                    <MyndButton onclick={this.onSubmit} style="width: 125px;">
                        NEXT
                    </MyndButton>
                </div>
            </form>
        );
    }
}

class SigninPassword extends Component {
    constructor(props, parent) {
        super(props, parent);
        this.password = "";
        this.onInput = this.onInput.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.form = null;
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.form && this.form.checkValidity()) {
            this.onHandleSubmit(this.password);
        }
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.onSubmit(e);
        }
    }

    onInput(e) {
        this.password = e.target.value;
    }

    onResetPassword(e) {
        //trigger password reset
        console.log("reset my password please");
    }

    async render() {
        return (
            <form ref={(f) => (this.form = f)} class="min-width-512 max-width-512" id="signinPasswordForm" novalidate={true}>
                <div class="form-group text-right">
                    <MyndInput
                        type="password"
                        required={true}
                        id="signinPassword"
                        placeholder="password"
                        oninput={this.onInput}
                        onKeyPress={this.onKeyPress}
                        error="a password is required"
                    />
                    <MyndButton onclick={this.onSubmit} style="width: 125px;">
                        SIGN IN
                    </MyndButton>
                </div>
            </form>
        );
    }
}
class SigninStage1 extends Component {
    async render() {
        return (
            <div class="min-width-512 max-width-512">
                <div class="display-5 mt-20">Sign in</div>
                <div class="mt-20">
                    <SigninEmail onHandleSubmit={this.onHandleSubmit} email={this.email} />
                </div>
            </div>
        );
    }
}

export class SigninTwoFactor extends Component {
    constructor(props, parent) {
        super(props, parent);
        this.onInput = this.onInput.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.form = null;
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.form && this.form.checkValidity()) {
            this.onHandleSubmit(this["1"] + this["2"] + this["3"] + this["4"] + this["5"] + this["6"]);
        }
    }

    onKeyDown(e) {
        if (e.key == "Enter") {
            this.onSubmit(e);
        } else if (e.key != "Backspace") {
            if (e.target.value || e.target.value.length > 0) {
                let n = parseInt(e.target.name);
                if (n < 6) {
                    n = n + 1;
                    this[`field${n}`].focus();
                    this[`field${n}`].value = e.key;
                }
                e.preventDefault();
            }
        }
    }

    onKeyUp(e) {
        if (e.key == "Backspace") {
            let n = parseInt(e.target.name);
            if (n > 1) {
                n = n - 1;
                this[`field${n}`].focus();
            }
        }
    }

    onInput(e) {
        const name = e.target.name;
        if (e.target.value != "" && e.target.value != null) {
            let n = parseInt(e.target.name);
            if (n < 6) {
                n = n + 1;
                this[`field${n}`].focus();
            }
        }

        this[name] = e.target.value;
    }

    async componentDidMount() {
        setTimeout(() => {
            if (this.field1) {
                this.field1.focus();
            }
        }, 25);
    }

    async render() {
        return (
            <form ref={(f) => (this.form = f)} class="min-width-512 max-width-512" id="signinTwoFactorForm" novalidate={true}>
                <div class="form-group d-flex flex-fill">
                    <MyndInput
                        iref={(f) => (this.field1 = f)}
                        type="number"
                        required={true}
                        pattern="[0-9]{1}"
                        name="1"
                        style="margin-right: 1rem; font-size: 3rem; margin-top: 0px;"
                        oninput={this.onInput}
                        onkeypress={this.onKeyPress}
                        onkeyup={this.onKeyUp}
                        onkeydown={this.onKeyDown}
                    />
                    <MyndInput
                        iref={(f) => (this.field2 = f)}
                        type="number"
                        required={true}
                        pattern="[0-9]{1}"
                        name="2"
                        style="margin-right: 1rem; font-size: 3rem; margin-top: 0px;"
                        oninput={this.onInput}
                        onkeypress={this.onKeyPress}
                        onkeyup={this.onKeyUp}
                        onkeydown={this.onKeyDown}
                    />
                    <MyndInput
                        iref={(f) => (this.field3 = f)}
                        type="number"
                        required={true}
                        pattern="[0-9]{1}"
                        name="3"
                        style="margin-right: 1rem; font-size: 3rem; margin-top: 0px;"
                        oninput={this.onInput}
                        onkeypress={this.onKeyPress}
                        onkeyup={this.onKeyUp}
                        onkeydown={this.onKeyDown}
                    />
                    <MyndInput
                        iref={(f) => (this.field4 = f)}
                        type="number"
                        required={true}
                        pattern="[0-9]{1}"
                        name="4"
                        style="margin-right: 1rem; font-size: 3rem; margin-top: 0px;"
                        oninput={this.onInput}
                        onkeypress={this.onKeyPress}
                        onkeyup={this.onKeyUp}
                        onkeydown={this.onKeyDown}
                    />
                    <MyndInput
                        iref={(f) => (this.field5 = f)}
                        type="number"
                        required={true}
                        pattern="[0-9]{1}"
                        name="5"
                        style="margin-right: 1rem; font-size: 3rem; margin-top: 0px;"
                        oninput={this.onInput}
                        onkeypress={this.onKeyPress}
                        onkeyup={this.onKeyUp}
                        onkeydown={this.onKeyDown}
                    />
                    <MyndInput
                        iref={(f) => (this.field6 = f)}
                        type="number"
                        required={true}
                        pattern="[0-9]{1}"
                        name="6"
                        style="font-size: 3rem; margin-top: 0px;"
                        oninput={this.onInput}
                        onkeypress={this.onKeyPress}
                        onkeyup={this.onKeyUp}
                        onkeydown={this.onKeyDown}
                    />
                </div>
                <div class="form-group text-right">
                    <MyndButton onclick={this.onSubmit} style="width: 125px;">
                        VERIFY
                    </MyndButton>
                </div>
            </form>
        );
    }
}

class SigninStage4 extends Component {
    async render() {
        return (
            <div class="min-width-512 max-width-512">
                <h4>One-time Password</h4>
                <div class="mt-20">
                    <SigninTwoFactor onHandleSubmit={this.onHandleSubmit} />
                </div>
            </div>
        );
    }
}

class SigninStage2 extends Component {
    async render() {
        return (
            <div class="min-width-512 max-width-512">
                <SigninWelcome onclick={this.onclick} email={this.email} />
                <div class="mt-20">
                    <SigninPassword onHandleSubmit={this.onHandleSubmit} />
                </div>
            </div>
        );
    }
}

class SigninNewPassword extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onInputConfirm = this.onInputConfirm.bind(this);
        this.onInputPassword = this.onInputPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.password = null;
        this.confirmPassword = null;
    }

    onKeyPress(e) {
        if (e.key == "Enter") {
            this.onSubmit(e);
        }
    }

    onInputConfirm(e) {
        this.confirmPassword = e.target.value;
        if (this.confirmPassword !== this.password) {
            e.target.setCustomValidity("passwords must match");
        } else {
            e.target.setCustomValidity("");
        }
    }

    onInputPassword(e) {
        this.password = e.target.value;
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.confirmPassword !== this.password) {
            return;
        }

        //we add one extra reg check for symbols only as the previous reg can return true if
        //such is provided: aB1b. As the regex sometimes ignores counting the \D\W aka non digits and non letters.
        if (!this.password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\D\W])[A-z\d\D\W]{4,}/g) || !this.password.match(/[\D\W]{1,}/g)) {
            return;
        }

        if (this.onHandleSubmit) {
            this.onHandleSubmit(this.password);
        }
    }

    async render() {
        return (
            <div class="min-width-512 max-width-512">
                <SigninWelcome onclick={this.onclick} email={this.email} />
                <div class="form-group text-right mt-4">
                    <h5 class="text-left">A New Password is Required</h5>
                    <p class="text-left">
                        Passwords must have at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character / symbol
                    </p>
                    <MyndInput
                        type="password"
                        required={true}
                        pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[\\d])(?=.*[\\D\\W])[A-z\\d\\D\\W]{4,}"
                        placeholder="password"
                        oninput={this.onInputPassword}
                        onkeypress={this.onKeyPress}
                        error="Must have at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character / symbol"
                    />
                    <MyndInput
                        type="password"
                        required={true}
                        placeholder="confirm password"
                        oninput={this.onInputConfirm}
                        onkeypress={this.onKeyPress}
                        error="passwords must match"
                    />
                    <MyndButton onclick={this.onSubmit} style="width: 125px;">
                        UPDATE
                    </MyndButton>
                </div>
            </div>
        );
    }
}

class SigninFailure extends Component {
    async render() {
        return (
            <div class="min-width-512 max-width-512 text-right">
                <h6 class="text-left">Oops, looks like you mistyped something. We could not find that username or password.</h6>
                <MyndButton onclick={this.onclick} style="width: 125px;">
                    TRY AGAIN
                </MyndButton>
            </div>
        );
    }
}

export default class Signin extends Component {
    constructor(props, parent) {
        super(props, parent);
        this.observe({ stage: 0 });

        this.email = "";
        this.onHandleEmailSubmit = this.onHandleEmailSubmit.bind(this);
        this.onHandlePasswordSubmit = this.onHandlePasswordSubmit.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onCancelNewPassword = this.onCancelNewPassword.bind(this);
        this.onHandleNewPasswordSubmit = this.onHandleNewPasswordSubmit.bind(this);
    }

    async onHandlePasswordSubmit(pass) {
        //submit final info

        try {
            Login.signOut();
            await Login.signIn(this.email, pass, (cb) => {
                this.onTwoFactor = cb;
                this.stage = 4;
            });
            //if we make it here we are signed in!
            if (Login.isSignedIn) {
                if (location.hash.startsWith("#/signout")) {
                    if (Login.hasGroup("OrderManager")) {
                        location.hash = "#/orders";
                    } else {
                        location.hash = "#/videos";
                    }
                } else {
                    this.hub.forceUpdate();
                }
            } else {
                //show error
                this.stage = 2;
            }
        } catch (e) {
            if (e === "new password required") {
                //else show error
                this.stage = 3;
            } else {
                this.stage = 2;
            }
        }
    }

    onCancelNewPassword() {
        this.stage = 1;
    }

    onBack() {
        if (this.stage > 0) {
            this.stage--;
        }
    }

    onHandleEmailSubmit(email) {
        this.email = email;
        this.stage++;
    }

    async onHandleNewPasswordSubmit(pass) {
        try {
            await Login.completeNewPasswordChallenge(pass);
            if (Login.isSignedIn) {
                if (location.hash.startsWith("#/signout")) {
                    if (Login.hasGroup("OrderManager")) {
                        location.hash = "#/orders";
                    } else {
                        location.hash = "#/videos";
                    }
                    window.location.reload();
                } else {
                    window.location.reload();
                }
            } else {
                this.stage = 2;
            }
        } catch (e) {
            this.stage = 2;
        }
    }

    async render() {
        return (
            <section class="d-flex flex-fill relative mr-4 ml-4">
                <MyndPage
                    show={this.stage === 0}
                    focusFirstInput={true}
                    enter="flex-fill d-flex align-items-center justify-content-center"
                    exit="flex-fill hidden d-flex align-items-center justify-content-center">
                    <SigninStage1 onHandleSubmit={this.onHandleEmailSubmit} email={this.email} />
                </MyndPage>
                <MyndPage
                    show={this.stage === 1}
                    focusFirstInput={true}
                    enter="flex-fill d-flex align-items-center justify-content-center"
                    exit="flex-fill hidden d-flex align-items-center justify-content-center">
                    <SigninStage2 onHandleSubmit={this.onHandlePasswordSubmit} onclick={this.onBack} email={this.email} />
                </MyndPage>
                <MyndPage
                    show={this.stage === 2}
                    enter="flex-fill d-flex align-items-center justify-content-center"
                    exit="flex-fill hidden d-flex align-items-center justify-content-center">
                    <SigninFailure onclick={this.onBack} />
                </MyndPage>
                <MyndPage
                    show={this.stage === 3}
                    enter="flex-fill d-flex align-items-center justify-content-center"
                    exit="flex-fill hidden d-flex align-items-center justify-content-center">
                    <SigninNewPassword onclick={this.onCancelNewPassword} email={this.email} onHandleSubmit={this.onHandleNewPasswordSubmit} />
                </MyndPage>
                <MyndPage
                    show={this.stage === 4}
                    enter="flex-fill d-flex align-items-center justify-content-center"
                    exit="flex-fill hidden d-flex align-items-center justify-content-center">
                    <SigninStage4 onHandleSubmit={this.onTwoFactor} />
                </MyndPage>
            </section>
        );
    }
}
