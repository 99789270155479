"use strict";

import { Login } from "../logic/login";

export class Navigation extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onHashChange = this.onHashChange.bind(this);
        this.onNavClick = this.onNavClick.bind(this);
        this.menuToggle = null;
    }

    onNavClick(e) {
        this.menuToggle.checked = false;
    }

    onHashChange() {
        setTimeout(() => {
            this.forceUpdate();
        }, 250);
    }

    async componentDidMount() {
        window.addEventListener("hashchange", this.onHashChange, false);
    }

    async componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange, false);
    }

    async render() {
        if (!Login.isSignedIn) {
            return (
                <nav class="d-flex nav navbar text-white align-items-center bg-dark">
                    <p class="logo-text">MyndVR</p>
                    <p style="margin: 0px; padding: 0px;">Contact: support@myndvr.com</p>
                </nav>
            );
        } else {
            let links = [
                <li class={window.location.hash.startsWith("#/devices") ? "nav-item active mr-4" : "nav-item mr-4"}>
                    <a class="nav-link" href="#/devices" onclick={this.onNavClick}>
                        DEVICES
                    </a>
                </li>,
                <li class={window.location.hash.startsWith("#/videos") ? "nav-item active mr-4" : "nav-item mr-4"}>
                    <a class="nav-link" href="#/videos" onclick={this.onNavClick}>
                        VIDEOS
                    </a>
                </li>,
            ];

            if (Login.hasGroup("Admin")) {
                links.splice(
                    1,
                    0,
                    <li class={window.location.hash.startsWith("#/communities") ? "nav-item active mr-4" : "nav-item mr-4"}>
                        <a class="nav-link" href="#/communities" onclick={this.onNavClick}>
                            COMMUNITIES
                        </a>
                    </li>
                );
                links.push(
                    <li class={window.location.hash.startsWith("#/apps") ? "nav-item active mr-4" : "nav-item mr-4"}>
                        <a class="nav-link" href="#/apps" onclick={this.onNavClick}>
                            APPS
                        </a>
                    </li>
                );
                links.push(
                    <li class={window.location.hash.startsWith("#/integration") ? "nav-item active mr-4" : "nav-item mr-4"}>
                        <a class="nav-link" href="#/integrations" onclick={this.onNavClick}>
                            INTEGRATIONS
                        </a>
                    </li>
                );
                links.push(
                    <li class={window.location.hash.startsWith("#/orders") ? "nav-item active mr-4" : "nav-item mr-4"}>
                        <a class="nav-link" href="#/orders" onclick={this.onNavClick}>
                            ORDERS
                        </a>
                    </li>
                );
            } else if (Login.hasGroup("OrderManager")) {
                links.push(
                    <li class={window.location.hash.startsWith("#/orders") ? "nav-item active mr-4" : "nav-item mr-4"}>
                        <a class="nav-link" href="#/orders" onclick={this.onNavClick}>
                            ORDERS
                        </a>
                    </li>
                );
            }

            links.push(
                <li class="d-flex align-items-center flex-row" style="margin: 0px 20px 0px 20px; padding: 0px;">
                    <i class="fas fa-user-circle" style="font-size: 2rem; margin-right: 10px;"></i>
                    {Login.email}
                </li>
            );
            links.push(
                <li class="nav-item">
                    <a class="nav-link" href="#/signout" onclick={this.onNavClick}>
                        SIGN OUT
                    </a>
                </li>
            );

            return (
                <nav
                    class="d-flex material-shadow navbar w-100 position-fixed navbar-dark text-white align-items-center bg-dark"
                    style="z-index:1000">
                    <input ref={(f) => (this.menuToggle = f)} type="checkbox" id="navbar" class="menu-button" />
                    <label class="menu-icon" for="navbar">
                        <span class="menu-icon-bars"></span>
                    </label>
                    <p class="logo-text">MyndVR</p>
                    <ul class="d-flex flex-row flex-fill align-items-center navbar-nav bg-dark" style="margin: 0px; margin-left: 2rem;">
                        {links}
                    </ul>
                    <p style="margin: 0px; padding: 0px;">Contact: support@myndvr.com</p>
                </nav>
            );
        }
    }
}
