'use strict';

import { MyndInput, MyndButton, TagInput } from "./common";
import Keywords from '../logic/keywords';
import {DateNumberToDateField, DateFieldToNumber} from '../logic/constants';

export class KeywordReleaseDateEditorView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.onApply = this.onApply.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onInput = this.onInput.bind(this);

        this.original = Date.now();
        this.observe({saving: false});
    }

    onInput(e) {
        this.video.releaseDate = DateFieldToNumber(e.target.value);
    }

    onCancel(e) {
        this.video.releaseDate = this.original;

        if(this.onclose) {
            this.onclose();
        }
    }

    onApply() {
        if(this.video) {
            this.save();
        }
    }

    async componentDidMount() {
        this.original = this.video.releaseDate || new Date(this.video.released_at).getTime();
    }

    async save() {
        this.saving = true;
        const success = await Keywords.updateReleaseDate(this.video.slug, this.video.category, this.video.releaseDate);

        if (!success) {
            this.video.releaseDate = this.original;
        }

        if(this.onclose) {
            this.onclose();
        }
    }

    async render() {
        const d = this.video.releaseDate || new Date(this.video.released_at).getTime();

        return (
            <div class='text-dark abs-tr d-flex flex-column min-width-256 max-width-256 bg-white material-shadow mt-2 mr-2'
                style={'z-index: 100;' + this.style}>
                <div class='p-2'>Release Date</div>
                <MyndInput type='date' value={DateNumberToDateField(d, false)} onInput={this.onInput} class='p-2 relative' />
                <div class='form-group d-flex justify-content-between p-2' style='margin-bottom: 0px;'>
                    <MyndButton type='light' onclick={this.onCancel} text={this.cancelTitle} style='height: 42px;' />
                    <MyndButton onclick={this.onApply} text={this.submitTitle} style='height: 42px;' />
                </div>
                <div class={'abs bg-white d-flex flex-column align-items-center justify-content-center ' + (this.saving ? '' : 'hidden')}
                    style='bottom: 0px; right:0px;'>
                    <h4>Applying Changes</h4>
                    <i class='fas fa-spinner spin' style='font-size: 32pt;'></i>
                </div>
            </div>
        );
    }
}

export class KeywordDescriptionEditorView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.onApply = this.onApply.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDescriptionInput = this.onDescriptionInput.bind(this);
        this.onTitleInput = this.onTitleInput.bind(this);
        
        this.originalTitle = '';
        this.originalDesc = '';
        this.observe({saving: false});

        this.textArea = null;
    }

    onTitleInput(e) {
        this.video.myndTitle = e.target.value;
    }

    onDescriptionInput(e) {
        this.video.myndDescription = e.target.value;
    }

    onCancel(e) {
        this.video.myndDescription = this.originalDesc;
        this.video.myndTitle = this.originalTitle;

        if(this.onclose) {
            this.onclose(e);
        }
    }

    onApply() {
        if(this.video) {
           this.save();
        }
    }

    async componentDidMount() {
        this.originalDesc = this.video.myndDescription;
        this.originalTitle = this.video.myndTitle;

        setTimeout(() => {
            if(this.textArea) {
                this.textArea.focus();
            }
        }, 1);
    }

    async save() {
        this.saving = true;
        const success = await Keywords.updateDescription(this.video.slug, this.video.category, this.video.myndDescription, this.video.myndTitle);

        if(!success) {
            this.video.myndDescription = this.original;
        }

        if(this.onclose) {
            this.onclose();
        }
    }

    async render() {
        return (
            <div class='text-dark abs-tr d-flex flex-column min-width-256 max-width-256 bg-white material-shadow mt-2 mr-2 p-2'
                style={'z-index: 100;' + this.style}>
                <MyndInput type='text' placeholder='title' oninput={this.onTitleInput} handleOnClear={() => {this.video.myndTitle = '';}} value={this.video.myndTitle} />
                <textarea ref={f => this.textArea = f} value={this.video.myndDescription} oninput={this.onDescriptionInput} class='p-2 relative' style='height: 240px;' />
                <div class='form-group d-flex justify-content-between p-2' style='margin-bottom; 0px;'>
                    <MyndButton type='light' onclick={this.onCancel} text={this.cancelTitle} style='height: 42px;' />
                    <MyndButton onclick={this.onApply} text={this.submitTitle} style='height: 42px;' />
                </div>
                <div class={'abs bg-white d-flex flex-column align-items-center justify-content-center ' + (this.saving ? '' : 'hidden')}
                    style='bottom: 0px; right:0px'>
                    <h4>Applying Changes</h4>
                    <i class='fas fa-spinner spin' style='font-size: 32pt;'></i>
                </div>
            </div>
        );
    }
}

export class KeywordEditorView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.onChange = this.onChange.bind(this);
        this.onApply = this.onApply.bind(this);
        this.onCancel = this.onCancel.bind(this);

        this.original = [];
        this.observe({saving: false});
    }

    onCancel(e) {
        this.video.tags = this.original;

        if(this.onclose) {
            this.onclose(e);
        }
    }

    onChange(tags) {
        this.tags = tags;
    }

    onApply() {
        if(this.video) {
           this.save();
        }
    }

    async componentDidMount() {
        this.original = this.video.tags.concat([]);
    }

    async save() {
        const valid = [];

        for(let i = 0; i < this.tags.length; i++) {
            if(this.tags[i].match(/^[A-Za-z0-9 '_\-]+$/gi)) {
                valid.push(this.tags[i]);
            }
        }

        this.saving = true;

        const success = await Keywords.updateKeywords(this.video.slug, this.video.category, valid);

        if (success) {
            this.video.tags = valid;
        }
        else {
            this.video.tags = this.original;
        }

        if(this.onclose) {
            this.onclose();
        }
    }

    async render() {
        return (
            <div class='text-dark abs-tr d-flex flex-column min-width-256 max-width-256 bg-white material-shadow mt-2 mr-2'
                style={'z-index: 100; ' + this.style}>
                <div class='p-2 allow-scroll-y relative bg-gray' style='height: 240px;'>
                    <TagInput tags={this.video.tags} onchange={this.onChange} />
                </div>
                <div class='form-group d-flex justify-content-between p-2' style='margin-bottom: 0px;'>
                    <MyndButton type='light' onclick={this.onCancel} text={this.cancelTitle} style='height: 42px;' />
                    <MyndButton onclick={this.onApply} text={this.submitTitle} style='height: 42px;' />
                </div>
                <div class={'abs bg-white d-flex flex-column align-items-center justify-content-center ' + (this.saving ? '' : 'hidden')}
                    style='bottom: 0px; right:0px'>
                    <h4>Applying Changes</h4>
                    <i class='fas fa-spinner spin' style='font-size: 32pt;'></i>
                </div>
            </div>
        );
    }
}