'use strict';

import Communities from './community';
import Keywords from './keywords';
import Axios from 'axios';
import {Login} from './login';
import {INITIAL_RELEASE_DATE} from './constants';

let API_URL = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/littlstar";

if(!window.location.hostname.startsWith('portal.')) {
    API_URL = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/littlstar";
}

const NEGATIVE_TERMS = ["the", "be", "to", "of", "and", "a", "in", "that", "have", "i", "it", "for", "not", "on", "with", "as", "you", "do", "at", "this", "but", "his", "by", "from", "they", "we", "say", "her", "she", "or", 
"an", "will", "my", "one", "all", "would", "there", "their", "what", "so", "up", "out", "if", "about", "who", "get", "which", "go", "me", "when", "make", "can", "like", "time", "no", "just", "him", "know", "take", "people", "into", "year", "your", "good", "some", "could", "them",
"see", "other", "than", "then", "now", "look", "only", "come", "its", "also", "think", "over", "after", "back", "use", "two", "how", "our", "work", "first", "well", "way", "even", "new", "want", "because", "any", "these", "give", "day", "most", "us"];

const NEGATIVE_TERM_AMOUNT = -2;
const POSITIVE_TERM_AMOUNT = 2;
const TAG_MATCH_AMOUNT = 2;
const EXACT_MATCH_AMOUNT = 20;
const TAG_EXACT_MATCH_AMOUNT = 20;
const NEAR_MATCH_AMOUNT = 4;
const INVALID_SEARCH_RESULT = -999999;

class SearchResult {
    constructor(weight, video) {
        this.weight = weight;
        this.video = video;
    }
}

class LittlstarHandler {
    constructor() {
        this.categoryCache = null;
        this.categoryIdCache = null;
        this.allCache = false;
        this.videoSlugCache = {};
    }   

    get all() {
        return new Promise(async (res,rej) => {
            if (this.allCache) {
                return res(this.categoryCache);
            }

            const cats = await this.categories;
            for(let i = 0; i < cats.length; i++) {
                const cat = cats[i];

                let vids = await this.getVideos(cat.id, null, INITIAL_RELEASE_DATE);
                cat.videos = vids.videos;

                while(vids.next) {
                    vids = await this.getVideos(cat.id, vids.next, INITIAL_RELEASE_DATE);
                    cat.videos = cat.videos.concat(vids.videos);
                }
            }

            this.allCache = true;
            return res(cats);
        });
    }

    async getAllWithActivationDate(date) {
        const cats = await this.categories;
        for(let i = 0; i < cats.length; i++) {
            const cat = cats[i];

            let vids = await this.getVideos(cat.id, null, date);
            cat.videos = vids.videos;

            while(vids.next) {
                vids = await this.getVideos(cat.id, vids.next, date);
                cat.videos = cat.videos.concat(vids.videos);
            }
        }

        this.allCache = true;
        return cats;
    }

    async getStreams(cat, vid) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'streams', 
                category: cat, 
                video: vid
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return null;
            }

            return result.data;
        }
        catch (e) {
            console.log(e.toString());
        }

        return null;
    }

    getCatById(id) {
        if(this.categoryIdCache) {
            return this.categoryIdCache[id];
        }

        return null;
    }

    //we will still need this for our custom keywords we implement
    async search(keyword) {
        if (keyword) {
            keyword = keyword.toLowerCase();
        }

        const cats = await this.all;
        const found = [];
        const final = [];
        const words = keyword ? keyword.split(' ') : '';

        let activationDate = null;
        try {
            activationDate = await Communities.activationDate;
        }
        catch (e) {
            activationDate = INITIAL_RELEASE_DATE;                        
        }

        for(let i = 0; i < cats.length; i++) {
            const vids = cats[i].videos;

            vids.forEach(v => {
                let weight = INVALID_SEARCH_RESULT;
                const c = v.releaseDate || new Date(v.released_at).getTime();
                if(c >= activationDate) {
                    let title = v.myndTitle || v.title;
                    let desc = v.myndDescription || v.description;                               

                    title = title.toLowerCase();
                    desc = desc.toLowerCase();

                    if(keyword === null) {
                        found.push(new SearchResult(1, v));
                        return;
                    }

                    if (title === keyword) {
                        if (weight === INVALID_SEARCH_RESULT) {
                            weight = 0;
                        }
                        weight += EXACT_MATCH_AMOUNT;
                    }
                    else if (title.indexOf(keyword) > -1) {
                        if (weight === INVALID_SEARCH_RESULT) {
                            weight = 0;
                        }
                        weight += NEAR_MATCH_AMOUNT;
                    }

                    if (desc === keyword) {
                        if (weight === INVALID_SEARCH_RESULT) {
                            weight = 0;
                        }
                        weight += EXACT_MATCH_AMOUNT;
                    }
                    else if (desc.indexOf(keyword) > -1) {
                        if (weight === INVALID_SEARCH_RESULT) {
                            weight = 0;
                        }
                        weight += NEAR_MATCH_AMOUNT;
                    }

                    if (v.keywords.indexOf(keyword) > -1 || v.tags.indexOf(keyword) > -1) {
                        if (weight === INVALID_SEARCH_RESULT) {
                            weight = 0;
                        }
                        weight += TAG_EXACT_MATCH_AMOUNT;
                    } 


                    for(let j = 0; j < words.length; j++) {
                        const word = words[j];

                        if (title.indexOf(word) > -1 || desc.indexOf(word) > -1) {
                            if (weight === INVALID_SEARCH_RESULT) {
                                weight = 0;
                            }

                            if (NEGATIVE_TERMS.indexOf(word) > -1) {
                                weight += NEGATIVE_TERM_AMOUNT;
                            }
                            else {
                                weight += POSITIVE_TERM_AMOUNT;
                            }
                        }

                        if(v.keywords.indexOf(word) > -1 || v.tags.indexOf(word) > -1) {
                            if (weight === INVALID_SEARCH_RESULT) {
                                weight = 0;
                            }
                            weight += TAG_MATCH_AMOUNT;
                        }
                    }

                    // if weight is INVALID_SEARCH_RESULT then not valid
                    // otherwise we add
                    if (weight !== INVALID_SEARCH_RESULT) {
                        found.push(new SearchResult(weight, v));
                    }
                }
            });
        }

        found.sort((a,b) => {
            const title1 = a.video.myndTitle || a.video.title;
            const title2 = b.video.myndTitle || b.video.title; 

            if (a.weight > b.weight) {
                return -1;
            }
            else if(a.weight < b.weight) {
                return 1;
            }
            else {
                if (title1 < title2) {
                    return -1;
                }
                else if(title1 > title2) {
                    return 1;
                }
            }

            return 0;
        });

        found.forEach(a => {
            final.push(a.video);
        });

        return final;
    }

    async getVideoBySlug(slug) {
        if(!Login.isSignedIn) {
            return null;
        }

        if(!this.allCache) {
            await this.all;
        }
            
        return this.videoSlugCache[slug];
    }

    async getVideo(cat, id) {
        if(!Login.isSignedIn) {
            return null;
        }

        if(!this.allCache) {
            await this.all;
        }

        const content = await this.getVideos(cat, null, INITIAL_RELEASE_DATE);
        const videos = content.videos;

        for(let i = 0; i < videos.length; i++) {
            if(videos[i].id === id) {
                return videos[i];
            }
        }

        return null;
    }
    
    async getVideos(cat, page, dateOffset) {
        if(!Login.isSignedIn) {
            return {videos: [], next: null};
        }

        if(!this.categoryCache) {
            await this.categories;
        }

        if(!dateOffset) {
            dateOffset = INITIAL_RELEASE_DATE;
        }

        //caching to save api calls
        if(this.allCache && this.categoryIdCache) {
            const category = this.categoryIdCache[cat];
            if(category && category.videos) {
                const videos = category.videos;
                const realVids = [];

                videos.forEach(v => {
                    const c = v.releaseDate || new Date(v.released_at).getTime();
                    if(c >= dateOffset) {
                        realVids.push(v);
                    }
                });

                return {videos: realVids, next: null};
            }
        }


        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'videos', 
                category: cat, 
                page: page
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return {videos: [], next: null};
            }

            const vids = result.data.videos;
            const realVids = [];

            let myndVids = {};
            try {
                myndVids = await Keywords.getByCategory(cat);
            }
            catch (e) {

            }

            vids.forEach(v => {
                this.videoSlugCache[v.slug] = v;
                v.category = cat;

                if(myndVids[v.slug]) {
                    const tags = myndVids[v.slug].keywords;
                    if(!tags) {
                        v.tags = [];
                    }
                    else {
                        v.tags = tags;
                    }

                    v.myndDescription = myndVids[v.slug].description;
                    v.myndTitle = myndVids[v.slug].title;
                    v.releaseDate = myndVids[v.slug].releaseDate;

                    if(!v.myndDescription) {
                        v.myndDescription = '';
                    }
                    if(!v.myndTitle) {
                        v.myndTitle = '';
                    }
                }
                else {
                    v.myndDescription = '';
                    v.myndTitle = '';
                    v.tags = [];
                }

                if(v.keywords) {
                    const keys = [];
                    v.keywords.forEach(k => {
                        keys.push(k.name);
                    });
                    v.keywords = keys;
                }
                else {
                    v.keywords = [];
                }

                const c = v.releaseDate || new Date(v.released_at).getTime();
                if(c >= dateOffset) {
                    realVids.push(v);
                }
            });

            return {videos: realVids, next: result.data.next};
        }
        catch (e) {
            console.log(e.toString());
        }

        return {videos: [], next: null};
    }

    get categories() {
        return new Promise(async (res, rej) => {
            if(!Login.isSignedIn) {
                return res([]);
            } 

            if(this.categoryCache) {
                return res(this.categoryCache);
            }

            try {
                const token = await Login.idToken;
                let result = await Axios.post(API_URL, JSON.stringify({
                    type:'categories'
                }), {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Authorization': token
                    }
                });

                result = result.data;

                if (!result.success) {
                    console.log(result.error);
                    return res([]);
                }

                const cats = result.data;

                this.categoryIdCache = {};

                cats.forEach(c => {
                    this.categoryIdCache[c.id] = c;
                });

                this.categoryCache = cats;

                return res(cats);
            }
            catch (e) {
                console.log(e.toString());
            }

            return res([]);
        });
    }

    filter(videos, filters) {
        const results = [];

        if(filters.length === 0) {
            return videos;
        }

        let containsEq = false;

        filters.forEach(f => {
            if(f.type === 'eq') {
                containsEq = true;
            }
        });

        videos.forEach(v => {
            let canAdd = false;
            for(let i = 0; i < filters.length; i++) {
                const f = filters[i];

                if(f.type === 'eq') {
                    if(v.tags.indexOf(f.value.toLowerCase()) > -1 || v.keywords.indexOf(f.value.toLowerCase()) > -1) {
                        canAdd = true;
                    }
                }
                else if(f.type === 'time') {
                    if(f.max > 0) {
                        if(v.duration / 60 > f.max) {
                            canAdd = false;
                        }
                        else if(v.duration / 60 < f.max && !containsEq) {
                            canAdd = true;
                        }
                    }
                    else if(f.min > 0) {
                        if(v.duration / 60 < f.min) {
                            canAdd = false;
                        }
                        else if(v.duration / 60 > f.min && !containsEq) {
                            canAdd = true;
                        }
                    }
                }
                else if(f.type === 'released') {
                    const time = v.releaseDate || new Date(v.released_at).getTime();
                    const now = Date.now();
                    const diff = now - time;
                    const days = (diff / (1000 * 60 * 60 * 24));
                    if(days > f.max) {
                        canAdd = false;
                    }
                    else if(days < f.max && !containsEq) {
                        canAdd = true;
                    }
                }
                else if(f.type == 'neq') {
                    if(v.tags.indexOf(f.value.toLowerCase()) > -1 || v.keywords.indexOf(f.value.toLowerCase()) > -1) {
                        canAdd = false;
                        break;
                    }
                    else if(!containsEq) {
                        canAdd = true;
                    }
                }
            }

            if(canAdd) {
                results.push(v);
            }
        });

        return results;
    }
}

export const Littlstar = new LittlstarHandler();