'use strict';

import Axios from "axios";
import {Login} from './login';
import {EventEmitter} from './eventemitter';

let API_URL_ADMIN_DEVICES = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/imei"
let API_URL_COMMUNITY_DEVICES = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/community/devices";
let API_URL_STATUS = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/community/device";

if(!window.location.hostname.startsWith('portal.')) {
    API_URL_ADMIN_DEVICES = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/imei";
    API_URL_COMMUNITY_DEVICES = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/community/devices";
    API_URL_STATUS = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/community/device";
}

let communityDevicesCache = null;

export default class Device extends EventEmitter {
    constructor(d) {
        super();
        this.name = d.deviceName;
        this.imei = d.imei;
        this.community = d.community;
        this.type = d.deviceType;
        this.subscriptionEmail = d.subscriptionEmail;
        
        this.online = false;
        this.lastOnline = new Date(2018,1,1).getTime();
        this.batteryLevel = 0;
        this.freeSpace = 0;
        this.totalSpace = 0;
        this.downloads = [];
    }

    /**
     * the object's freeSpace
     * totalSpace and downloads
     * will be set after calling this
     */
    async status() {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_STATUS, JSON.stringify({
                type: 'status', 
                id: this.imei
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            this.batteryLevel = result.data.batteryLevel;
            this.lastOnline = result.data.lastOnline;
            this.online = result.data.online;
            
            this.freeSpace = result.data.freeSpace;
            this.totalSpace = result.data.totalSpace;

            this.downloads = result.data.downloads;

            this.emit('status');

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async removeDownload(slug) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_STATUS, JSON.stringify({
                type: 'remove-download', 
                id: this.imei, 
                slug: slug
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            await this.status();
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async addDownload(slug) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_STATUS, JSON.stringify({
                type: 'add-download', 
                id: this.imei, 
                slug: slug
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            await this.status();
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async updateType(type) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_ADMIN_DEVICES, JSON.stringify({
                type: 'update-type', 
                id: this.imei, 
                deviceType: type
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            this.type = type;
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async updateName(name) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_ADMIN_DEVICES, JSON.stringify({
                type: 'update-name', 
                id: this.imei,
                name: name
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            this.name = name;
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async updateSubscriptionEmail(subscriptionEmail) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_ADMIN_DEVICES, JSON.stringify({
                type: 'update-subscription-email', 
                id: this.imei, 
                subscriptionEmail: subscriptionEmail
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            this.subscriptionEmail = subscriptionEmail;
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async updateCommunity(community) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_ADMIN_DEVICES, JSON.stringify({
                type: 'update-community', 
                id: this.imei, 
                community: community
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            this.community = community;
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async remove() {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_ADMIN_DEVICES, JSON.stringify({
                type: 'remove', id: 
                this.imei
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    async add() {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_ADMIN_DEVICES, JSON.stringify({
                type: 'add', 
                id: this.imei, 
                name: this.name, 
                community: this.community, 
                deviceType: this.type
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }
       
            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false; 
    }

    static async all() {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_ADMIN_DEVICES, JSON.stringify({
                type: 'all'
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            return result || {total: 0, perPage: 100, items: []};
        }
        catch (e) {
            console.log(e.toString());
        }

        return {total: 0, perPage: 100, items: []};
    }

    static async allCommunity() {
        const items = [];
        let page = 1;

        if(communityDevicesCache) {
            communityDevicesCache.sort((a,b) => {
                if(a.community < b.community) {
                    return -1;
                }
                else if(a.community > b.community) {
                    return 1;
                }
                else {
                    if(a.deviceName < b.deviceName) {
                        return -1;
                    }
                    else if(a.deviceName > b.deviceName) {
                        return 1;
                    }
                }
    
                return 0;
            });
            return communityDevicesCache;
        }

        let p = await Device.communityPage(page++);

        while(p.items.length > 0) {
            p.items.forEach(i => {
                items.push(i);
            });

            if(p.items.length >= p.total) {
                break;
            }

            try {
                p = await Device.communityPage(page++);
            }
            catch (e) {
                console.log(e);
                break;
            }
        }

        items.sort((a,b) => {
            if(a.community < b.community) {
                return -1;
            }
            else if(a.community > b.community) {
                return 1;
            }
            else {
                if(a.deviceName < b.deviceName) {
                    return -1;
                }
                else if(a.deviceName > b.deviceName) {
                    return 1;
                }
            }

            return 0;
        });

        communityDevicesCache = items;
        return items;
    }

    static async communityPage(p) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL_COMMUNITY_DEVICES, JSON.stringify({
                type: 'page', 
                page: parseInt(p)
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            return result || {total: 0, perPage: 100, items: []};
        }
        catch (e) {
            console.log(e.toString());
        }

        return {total: 0, perPage: 100, items: []};
    }
}