'use strict';

export default class VrRenderer {
    constructor(videoElement) {
        this.videoElement = videoElement;

        this.camera = null;
        this.scene = null;
        this.mesh = null;
        this.geometry = null;
        this.material = null;
        this.texture = null;
        this.renderer = null;
        this.container = this.videoElement.parentElement;
        this.onResize = this.onResize.bind(this);
        this.render = this.render.bind(this);
        this.isDisposing = false;

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        this.mouseIsDown = false;

        this.lat = 0;
        this.lon = 180;

        this.downLat = this.lat;
        this.downLon = this.lon;

        this.downX = null;
        this.downY = null;
    }

    onResize() {
        this.camera.aspect = this.videoElement.clientWidth / this.videoElement.clientHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.videoElement.clientWidth, this.videoElement.clientHeight);
    }

    render() {
        if(this.isDisposing) {
            return;
        }

        this.updateCameraRotation();

        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(this.render);
    }

    onMouseMove(e) {
        e.preventDefault();

		if(this.mouseIsDown) {
			if(this.downX === null) {
				this.downX = e.clientX;
			}
			if(this.downY === null) {
				this.downY = e.clientY;
			}

			this.lon = ( e.clientX - this.downX ) * 0.1 + this.downLon;
			this.lat = ( this.downY - e.clientY ) * 0.1 + this.downLat;
		}
    }

    updateCameraRotation() {
        let lat = Math.max(-85, Math.min(85, this.lat));
        let lon = this.lon;
        let phi = THREE.Math.degToRad( 90 - lat );
        let theta = THREE.Math.degToRad( lon );

        this.camera.target.x = 500 * Math.sin( phi ) * Math.cos( theta );
        this.camera.target.y = 500 * Math.cos( phi );
        this.camera.target.z = 500 * Math.sin( phi ) * Math.sin( theta );

        this.camera.lookAt( this.camera.target );
    }

    onMouseDown(e) {
        e.preventDefault();

        this.downX = e.clientX;
        this.downY = e.clientY;
    
        this.downLat = this.lat;
        this.downLon = this.lon;

        this.mouseIsDown = true;
    }

    onMouseUp(e) {
        e.preventDefault();

        this.mouseIsDown = false;
    }

    init() {
        this.isDisposing = false;
        const container = this.videoElement.parentElement;
        this.videoElement.style.opacity = '0';

        this.texture = new THREE.VideoTexture(this.videoElement);
        this.texture.minFilter = THREE.LinearFilter;
        this.texture.magFilter = THREE.LinearFilter;
        this.texture.format = THREE.RGBFormat;

        this.camera = new THREE.PerspectiveCamera( 45, this.videoElement.clientWidth / this.videoElement.clientHeight, 1, 1100 );
        this.camera.target = new THREE.Vector3( 0, 0, 0 );

        let lat = 0;
        let lon = 180;
        let phi = THREE.Math.degToRad( 90 - lat );
        let theta = THREE.Math.degToRad( lon );

        this.camera.target.x = 500 * Math.sin( phi ) * Math.cos( theta );
        this.camera.target.y = 500 * Math.cos( phi );
        this.camera.target.z = 500 * Math.sin( phi ) * Math.sin( theta );

        this.camera.lookAt( this.camera.target );

        this.camera.updateProjectionMatrix();
        
        this.scene = new THREE.Scene();

        this.geometry = new THREE.SphereGeometry(500, 60, 60);
        this.geometry.scale(-1, 1, 1); 
        this.material = new THREE.MeshBasicMaterial({map: this.texture});
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.scene.add(this.mesh);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setPixelRatio(this.videoElement.clientWidth / this.videoElement.clientHeight);
        this.renderer.setSize(this.videoElement.clientWidth, this.videoElement.clientHeight);

        //properly size to window area
        this.renderer.domElement.style.position = 'absolute';
        this.renderer.domElement.style.top = '0px';
        this.renderer.domElement.style.left = '0px';
        this.renderer.domElement.style.right = '0px';
        this.renderer.domElement.style.bottom = '0px';

        container.appendChild(this.renderer.domElement);

        window.addEventListener('resize', this.onResize, false);
        window.addEventListener('mouseup', this.onMouseUp, false);
        window.addEventListener('mousedown', this.onMouseDown, false);
        window.addEventListener('mousemove', this.onMouseMove, false);

        this.render();
    }

    dispose() {
        this.isDisposing = true;
        window.removeEventListener('resize', this.onResize, false);
        window.removeEventListener('mouseup', this.onMouseUp, false);
        window.removeEventListener('mousedown', this.onMouseDown, false);
        window.removeEventListener('mousemove', this.onMouseMove, false);
    }
}