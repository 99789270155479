"use strict";

import Hub from "./hub";
import { Navigation } from "./nav";
import { Login } from "../logic/login";
import TwoFactor from "./twofactor";

class MainPage extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.mfaEnabled = true;
        this.onSessionAvailable = this.onSessionAvailable.bind(this);
    }

    async onSessionAvailable() {
        this.mfaEnabled = await Login.isMFAEnabled();
        this.forceUpdate();
    }

    async mounted() {
        try {
            Login.sessionCallback = this.onSessionAvailable;
            const sess = await Login.sessionData;
        } catch (e) {
            console.log(e);
        }
    }

    async componentDidMount() {
        this.mounted();
    }

    async render() {
        if (Login.isSignedIn && !this.mfaEnabled) {
            return (
                <div class="w-100 d-flex" key="main-view">
                    <TwoFactor />
                </div>
            );
        } else if (location.hash.startsWith("#/print/videos")) {
            return (
                <div class="w-100" key="main-view">
                    <Hub root={this} hash={Login.isSignedIn ? location.hash : null} />
                </div>
            );
        } else {
            return (
                <div class="w-100 d-flex flex-column" key="main-view">
                    <Navigation root={this} hash={Login.isSignedIn ? location.hash : null} />
                    <Hub root={this} hash={Login.isSignedIn ? location.hash : null} />
                </div>
            );
        }
    }
}

const InitPage = async () => {
    React.render(<MainPage />, document.getElementById("main"));
};

InitPage();
