'use strict';

import { MyndButton } from "./common";
import BulkUpdate from '../logic/bulkupdate';
import CSVParser from '../logic/csvparser';

export default class BulkView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.observe({progress: 0, processing: false, file: null});

        this.onFileChanged = this.onFileChanged.bind(this);
        this.onBulk = this.onBulk.bind(this);
    }

    onFileChanged(e) {
        const f =  e.target.files[0];

        if(f.name.endsWith(".csv")) {
            const fr = new FileReader();
            fr.onload = (e) => {
                this.file = e.target.result;
            };
            fr.readAsText(f);
        }
    }

    onBulk() {
        if(this.file) {
            const csv = new CSVParser(this.file);
            this.progress = 0;
            this.process(csv);
        }
    }

    process(csv) {
        this.processing = true;
        BulkUpdate.process(csv, true, (p) => {
            this.progress = p;

            if(p >= 100) {
                this.file = null;
                this.processing = false;
            }
        });
    }

    async render() {
        return (
            <div class='flex-fill d-flex flex-column allow-scroll-y relative' style='margin-top: 64px;'>
                <div class='min-width-512 p-4 align-self-center justify-self-center'>
                    <h1 class='display-1 text-center'>Bulk Video Update</h1>
                    <input type='file' onChange={this.onFileChanged} accept='.csv' />
                    <p>
                        CSV Columns as follows: slug,tags,release,title,description
                    </p>
                    <p>
                        If you do not want to update a field, simply leave the column in the row blank. All columns must be included.
                    </p>
                    <MyndButton onclick={this.onBulk} style={this.processing || this.file == null ? 'display: none;' : ''}>Apply</MyndButton>
                    <div class={'progress w-100' + (this.processing ? '' : 'hidden')} style='height: 10px;'>
                        <div class='progress-bar' style={`width: ${this.progress}%`} />
                    </div>
                    <p>
                        {Math.round(this.progress) + '%'}
                    </p>
                </div>
            </div>
        )
    }
};