'use strict';

import Axios from "axios";
import {Login} from './login';

let API_URL = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/keywords";

if(!window.location.hostname.startsWith('portal.')) {
    API_URL = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/keywords"; 
}

export default class Keywords {
    static async updateReleaseDate(video, category, date) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'update-release', 
                video: video, 
                category: category, 
                date: date
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async updateKeywords(video,category,keywords) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'update-keywords', 
                video: video, 
                category: category, 
                keywords: keywords
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async updateDescription(video,category,desc,title) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'update-description', 
                video: video, 
                category: category, 
                description: desc, 
                title: title
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async getByCategory(category) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'category', 
                category: category
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return {};
            }

            return result.data;
        }
        catch (e) {
            console.log(e.toString());
        }

        return {};
    }
}