'use strict';

import Axios from "axios";
import {Login} from './login'
import { INITIAL_RELEASE_DATE } from "./constants";

let API_URL = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/community";
let API_URL_COMMUNITY_DEVICES = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/community/devices";

if(!window.location.hostname.startsWith('portal.')) {
    API_URL = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/community"; 
    API_URL_COMMUNITY_DEVICES = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/community/devices";
}

//cache activation date so less calls are made to the api
let ACTIVATION_DATE = null;

const communityLookup = {};

export default class Communities {
    static clearCache() {
        ACTIVATION_DATE = null;
    }

    static isAppBundleAssigned(community, bundle) {
        if(communityLookup[community]) {
            return communityLookup[community].apps.indexOf(bundle) > -1;
        }
    }

    /**
     * Date should be in the standard javascript getTime() format or Date.now()
     * @param {String} id 
     * @param {Number} date 
     */
    static async updateCommunityActivation(id, date) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'update-community-activation', 
                id: id,
                date: date
            }),
            {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async updateParentCompany(id, name) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'update-community-parent', 
                id: id, 
                name: name
            }),
            {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });
            
            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async removeApp(id, bundle) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'remove-app', 
                id: id, 
                bundle: bundle
            }),
            {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async addApp(id, bundle) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'add-app', 
                id: id, 
                bundle: bundle
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    /**
     * Date should be in the standard javascript getTime() format or Date.now()
     * @param {String} id 
     * @param {Number} date 
     */
    static async updateActivation(id, date) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'update-activation', 
                id: id, 
                date: date
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    /**
     * Returns activation date of that community 
     * if user is part of a community
     */
    static get activationDate() {
        return new Promise(async (res, rej) => {
            try {
                if (Login.hasGroup('Admin')) {
                    return res(INITIAL_RELEASE_DATE);
                }

                if(ACTIVATION_DATE) {
                    return res(ACTIVATION_DATE);
                }

                const token = await Login.idToken;
                let result = await Axios.post(API_URL_COMMUNITY_DEVICES, JSON.stringify({
                    type: 'activation'
                }),{
                    headers: {
                        'Content-Type': 'text/plain',
                        'Authorization': token
                    }
                });

                result = result.data;

                if(!result.success) {
                    console.log(result.error);
                    return res(INITIAL_RELEASE_DATE);
                }

                ACTIVATION_DATE = result.data;
                return res(result.data);
            }
            catch (e) {
                console.log(e.toString());
            }

            return res(INITIAL_RELEASE_DATE);
        });
    }

    static async remove(id) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'remove', 
                id: id
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async add(name) {   
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'add', 
                id: name
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async all() {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'all'
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result || !result.items) {
                return res({total: 0, perPage: 100, items: []});
            }

            result.items.forEach(c => {
                communityLookup[c.name] = c;
                c.parentCompany = c.parentCompany || 'Unknown';
            });

            return result;
        }
        catch (e) {
            console.log(e.toString());
        }

        return {total: 0, perPage: 100, items: []};
    }
}