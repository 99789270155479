'use strict';

import {VideoListItem} from './videolist';
import {Login} from '../logic/login';
import {Littlstar} from '../logic/littlstar';
import Communities from '../logic/community';


export default class VideoListPrintView extends Component {
    async render() {
        let cats = [];
        
        try {
            const sess = await Login.sessionData;

            if(!sess || !sess.isValid()) {
                return h('h1', {}, 'Not Authorized');
            }

            let activationDate = null;

            try {
                activationDate = await Communities.activationDate;
            }
            catch (e) {
                activationDate = INITIAL_RELEASE_DATE;
            }

            cats = await Littlstar.getAllWithActivationDate(activationDate);
        }
        catch (e) {
            console.log(e);
        }

        const list = [];

        for(let i = 0; i < cats.length; i++) {
            const cat = cats[i];

            cat.videos.forEach(v => {
                const vid = new VideoListItem({video: v});
                const smallBanner = vid.imageBannerSmall;
                let desc = v.myndDescription;

                if(!desc || desc.length === 0) {
                    desc = v.description;
                }

                list.push(<div class='p-2'>
                            <h5>{cat.name}</h5>
                            <img style='max-height: 128px;' src={smallBanner} />
                            <p style='margin: 0px;'>
                                {`${v.title} | Duration: ${vid.duration} | Experience Level: ${vid.experienceLevel}`}
                            </p>
                            <small>{desc}</small>
                        </div>);
            });
        }

        return (
            <div>
                {list}
            </div>
        );
    }
}