"use strict";

import Signin from "./signin";
import VideoListPrintView from "./videolistprintview";
import BulkView from "./bulk";
import VideosList from "./videolist";
import CommunitiesView from "./communities";
import AppsList from "./apps";
import IntegrationsView from "./integrations";
import DevicesView from "./devices";
import SubscriptionsView from "./subscriptions";
import { Login } from "../logic/login";
import { GETQUERY } from "../logic/constants";

export default class Hub extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onHashChange = this.onHashChange.bind(this);
    }

    onHashChange() {
        console.log("hash changed: " + location.hash);

        if (location.hash.startsWith("#/signout")) {
            Login.globalSignout();
        } else {
            //refresh token
            //main.jsx will do a force update
            //once session is refreshed successfully
            if (!Login.isSignedIn) {
                Login.refreshToken();
            }
        }

        this.forceUpdate();
    }

    async componentDidMount() {
        window.addEventListener("hashchange", this.onHashChange, false);
    }

    async componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange, false);
    }

    async render() {
        if (!Login.isSignedIn) {
            return <Signin hub={this.root} key="main" />;
        } else if (location.hash.startsWith("#/print/videos")) {
            return <VideoListPrintView key="main" />;
        } else if (location.hash.startsWith("#/bulk/videos")) {
            return <BulkView key="main" />;
        } else if (!location.hash || location.hash == "#/" || location.hash == "#" || location.hash.startsWith("#/videos")) {
            const query = GETQUERY(window.location.hash);
            console.log(query);
            let cat = query.category ? parseInt(query.category) : null;
            let vid = query.video ? parseInt(query.video) : null;
            return <VideosList key="main" category={cat} vid={vid} search={query.search || null} />;
        } else if (location.hash.startsWith("#/communities")) {
            const query = GETQUERY(window.location.hash);
            console.log(query);
            let edit = query.edit ? parseInt(query.edit) : null;
            return <CommunitiesView key="main" edit={edit} />;
        } else if (location.hash.startsWith("#/apps")) {
            const query = GETQUERY(window.location.hash);
            console.log(query);
            let edit = query.edit ? parseInt(query.edit) : null;
            return <AppsList key="main" edit={edit} />;
        } else if (location.hash.startsWith("#/integration")) {
            const query = GETQUERY(window.location.hash);
            console.log(query);
            return <IntegrationsView key="main" edit={query.edit || null} />;
        } else if (location.hash.startsWith("#/orders")) {
            const query = GETQUERY(window.location.hash);
            console.log(query);
            return (
                <SubscriptionsView key="main" edit={query.edit || null} search={query.search || null} page={query.page ? parseInt(query.page) : 1} />
            );
        } else if (location.hash.startsWith("#/devices")) {
            const query = GETQUERY(window.location.hash);
            console.log(query);
            let edit = query.edit ? query.edit : null;
            let community = query.community ? query.community : null;
            return <DevicesView key="main" edit={edit} community={community} />;
        }
    }
}
