"use strict";

import { ListView, MyndButton, MyndInput } from "./common";
import Subscription from "../logic/subscription";
import Device from "../logic/device";
import { MultiDeviceSelector } from "./devices";
import { Login } from "../logic/login";

class SubscriptionDeviceItem extends Component {
    constructor(o, parent) {
        super(o, parent);
    }

    async render() {
        return (
            <div class="list-item" style="margin-bottom: 0.25rem !important;">
                <h3 class="subtitle">{this.item.imei}</h3>
                <h2 class="title">{this.item.deviceName}</h2>
            </div>
        );
    }
}

class SubscriptionDevicesList extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            items: [],
            showAdd: false,
            showSaving: false,
        });

        this.onAddClick = this.onAddClick.bind(this);
        this.onDevicesSelected = this.onDevicesSelected.bind(this);
        this.errorMessage = "Loading";
    }

    async onDevicesSelected(devices) {
        this.showSaving = true;

        for (let i = 0; i < devices.length; ++i) {
            const d = devices[i];
            try {
                await d.updateSubscriptionEmail(this.order.email);
            } catch (e) {
                console.log(e);
            }
        }

        this.disabled = true;
        this.showSaving = false;
        this.disabled = false;

        this.showAdd = false;

        this.refresh();
    }

    async componentDidMount() {
        this.refresh();
    }

    async refresh() {
        let available = (await Device.all()).items;
        const items = [];

        let key = 0;
        available.forEach((f) => {
            if (f.subscriptionEmail === this.order.email) {
                items.push(<SubscriptionDeviceItem key={`sub-device-${key}`} item={f} />);
                ++key;
            }
        });

        if (items.length === 0) {
            this.errorMessage = "No devices found";
        } else {
            this.errorMessage = "Loading";
        }

        this.items = items;
    }

    onAddClick(e) {
        this.showAdd = !this.showAdd;
    }

    async render() {
        let addWindow = null;
        if (this.showAdd) {
            addWindow = (
                <MultiDeviceSelector
                    showAll={true}
                    saving={this.showSaving}
                    onSelect={this.onDevicesSelected}
                    oncancel={this.onAddClick}
                    submitTitle="ASSIGN"
                    cancelTitle="CANCEL"
                />
            );
        }

        return (
            <div class="min-width-256 max-width-512 p-4 rounded bg-light p-4 material-shadow relative">
                <h1 class="display-6">Devices</h1>
                <div class="p-2 allow-scroll-y relative" style="max-height: 645px;">
                    <ListView items={this.items} noItemsMessage={this.errorMessage} />
                </div>
                <div class="p-2 d-flex flex-row justify-content-end">
                    <MyndButton type="primary round-button" onclick={this.onAddClick} style="width: 64px; height: 64px;">
                        <i class="fas fa-plus"></i>
                    </MyndButton>
                </div>
                {addWindow}
            </div>
        );
    }
}

class SubscriptionDetailsView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.observe({
            shipping: false,
            status: {
                subscription: { id: "", isSubscriptionActive: false, nextBillDate: 0 },
                customer: { email: "", shipping: { name: "", address: { line1: "", line2: "", postal_code: "", state: "", city: "" } } },
            },
        });

        this.onShip = this.onShip.bind(this);
    }

    async onShip(e) {
        if (this.shipping) {
            return;
        }

        const isConfirmed = confirm(
            `Ready to ship for ${this.order.email} : ${this.order.shortId || this.order.id.split("_")[2].substring(0, 16).toUpperCase()}?`
        );

        if (!isConfirmed) {
            return;
        }

        this.shipping = true;
        const success = await Subscription.ship(this.order.email, this.order.id);
        if (success) {
            let nextBillDate = Date.now() + 60 * 60 * 24 * 30 * 1000;

            if (this.order.plan === "plan_HC30mHKnO6Fv5W" || this.order.plan == "plan_HC34YHSXoISrxM") {
                nextBillDate = Date.now() + 60 * 60 * 24 * 365 * 1000;
            }

            this.order.state = "processed";
            this.status.subscription = this.status.subscription || { id: "", isSubscriptionActive: false, nextBillDate: 0 };
            this.status.subscription.id = success;
            this.status.subscription.nextBillDate = nextBillDate;
            this.status.subscription.isSubscriptionActive = true;
        }
        this.shipping = false;
    }

    async componentDidMount() {
        this.getStripeStatus();
    }

    async getStripeStatus() {
        const status = await Subscription.status(this.order.email);
        console.log(status);
        if (status) {
            this.status = status;
        }
    }

    async render() {
        const contact = this.status ? this.status.customer || {} : {};
        const subscription = this.status ? this.status.subscription || {} : {};
        const nextBillDate = subscription.nextBillDate || 0;
        const shipping = contact.shipping || {};
        const address = shipping.address || {};
        const spinner = <i class="fas fa-spinner spin"></i>;

        return (
            <div class="d-flex flex-row flex-wrap ml-auto mr-auto margin-auto-remove" style="margin-top: 64px;">
                <div class="min-width-512 max-width-1024 rounded bg-light material-shadow">
                    <div class="bg-dark p-4 text-white rounded">
                        <h1 class="display-6">{this.order.email}</h1>
                        <h5 class="mt-20 bg-white text-dark p-4 rounded">
                            Order: {this.order.shortId || this.order.id.split("_")[2].substring(0, 16).toUpperCase()}
                        </h5>
                    </div>
                    <div class="shipping rounded p-4 mt-2">
                        <div class="p-1 large-icon">
                            <i class="fas fa-address-card"></i>
                        </div>
                        <div>
                            <h4>Contact Information</h4>
                            {contact.email || ""} <br />
                            {contact.phone || ""}
                        </div>
                    </div>
                    <div class="shipping bg-white p-4 rounded mt-2">
                        <div class="p-1 large-icon">
                            <i class="fas fa-shipping-fast"></i>
                        </div>
                        <div>
                            <h4>Shipping Information</h4>
                            {shipping.name || ""} <br />
                            {(address.line1 || "") + (address.line2 ? `, ${address.line2}` : "")} <br />
                            {address.city || ""}, {address.state || ""} {address.postal_code || ""}
                        </div>
                    </div>
                    <div class="shipping rounded p-4 mt-2">
                        <div class="p-1 large-icon">
                            <i class="fas fa-info-circle"></i>
                        </div>
                        <div>
                            <h4>Status</h4>
                            Order Status: {this.order.state} <br />
                            Subscription ID: {subscription.id || "No Subscription"} <br />
                            Subscription Active: {subscription.isSubscriptionActive ? "Yes" : "No"} <br />
                            {nextBillDate > 0 ? `Next Bill Date: ${new Date(nextBillDate).toLocaleString()}` : ""}
                        </div>
                    </div>
                    <div class="d-flex w-100 justify-content-between pl-4 pr-4 pb-4" style={this.order.state !== "new" ? "display: none;" : ""}>
                        <MyndButton type="success" onclick={this.onShip} text={this.shipping ? spinner : "READY TO SHIP"}></MyndButton>
                    </div>
                </div>
                <SubscriptionDevicesList order={this.order} />
            </div>
        );
    }
}

class SubscriptionListItem extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onView = this.onView.bind(this);
    }

    onView(e) {
        if (this.search) {
            window.location.hash = `#/orders?edit=${this.order.id.split("_")[2]}&page=${this.page}&search=${encodeURIComponent(this.search || "")}`;
        } else {
            window.location.hash = `#/orders?edit=${this.order.id.split("_")[2]}&page=${this.page}`;
        }
    }

    async render() {
        return (
            <div class="d-flex align-items-center flex-row p-1 m-1 bg-light material-shadow">
                <div class="vw-2 word-wrap-break p-1">{new Date(this.order.timestampCreated || 0).toLocaleString()}</div>
                <div class="vw-2 word-wrap-break p-1">{this.order.email}</div>
                <div class="vw-2 word-wrap-break p-1">{this.order.id.split("_")[2]}</div>
                <div class="vw-2 word-wrap-break p-1">{this.order.shortId || this.order.id.split("_")[2].substring(0, 16).toUpperCase()}</div>
                <div class="vw-2 p-1 d-flex align-items-center">
                    <i class="fas fa-check-circle" style={this.order.state !== "new" ? "display: none;" : "font-size: 2rem; margin-right: 1rem;"}></i>{" "}
                    {this.order.state}
                </div>
                <div class="vw-2 p-1 d-flex justify-content-end">
                    <MyndButton class="round-button" style="height: 46px; width: 46px; margin: 0px !important;" onclick={this.onView}>
                        <i class="fas fa-pen"></i>
                    </MyndButton>
                </div>
            </div>
        );
    }
}

class SubscriptionsPager extends Component {
    constructor(o, parent) {
        super(o, parent);
    }

    async render() {
        return (
            <div class="d-flex justify-content-center align-items-center relative p-4">
                <MyndButton type="primary round-button m-1" onclick={this.onPrevious} style={this.page > 1 ? "height: 36px;" : "display: none;"}>
                    <i class="fas fa-chevron-left"></i>
                </MyndButton>
                <MyndInput
                    type="number"
                    placeholder="page"
                    pattern="[0-9]+"
                    style="width: 64px;"
                    class="text-center"
                    value={this.page}
                    oninput={this.onPageChanged}
                />
                <span class="display-10 ml-2">of {Math.ceil(this.lastTotal / this.lastPerPage)}</span>
                <MyndButton
                    type="primary round-button m-1"
                    onclick={this.onNext}
                    style={this.page * this.lastPerPage < this.lastTotal ? "height: 36px;" : "display: none;"}>
                    <i class="fas fa-chevron-right"></i>
                </MyndButton>
            </div>
        );
    }
}

export default class SubscriptionsView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.observe({
            items: [],
        });

        this.sort = { by: "state", dir: 0 };
        this.lastTotal = 0;
        this.lastPerPage = 100;
        this.lookup = {};

        this.onPageChanged = this.onPageChanged.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onSearchInput = this.onSearchInput.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);

        this.searchTimeout = null;
        this.errorMessage = "Loading";
    }

    onSort(e) {
        if (this.sort.by !== e.target.id) {
            this.sort.by = e.target.id;
            this.sort.dir = 1;
        } else {
            if (this.sort.dir === 1) {
                this.sort.dir = 0;
            } else {
                this.sort.dir = 1;
            }
        }

        this.refresh();
    }

    onSearchClear() {
        this.search = null;
        window.location.hash = `#/orders?page=${this.page}`;
        this.refresh();
    }

    onSearchInput(e) {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            window.location.hash = `#/orders?page=${this.page}&search=${encodeURIComponent(e.target.value || "")}`;
        }, 500);
    }

    onPrevious(e) {
        if (this.page > 1) {
            if (this.search) {
                window.location.hash = `#/orders?page=${this.page - 1}&search=${encodeURIComponent(this.search || "")}`;
            } else {
                window.location.hash = `#/orders?page=${this.page - 1}`;
            }
        }
    }

    onNext(e) {
        if ((this.page - 1) * this.lastPerPage < this.lastTotal) {
            if (this.search) {
                window.location.hash = `#/orders?page=${this.page + 1}&search=${encodeURIComponent(this.search || "")}`;
            } else {
                window.location.hash = `#/orders?page=${this.page + 1}`;
            }
        }
    }

    async componentDidMount() {
        this.refresh();
    }

    async componentDidUpdate(previous) {
        if (previous["page"] !== undefined || previous["search"] !== undefined) {
            this.refresh();
        }
    }

    onPageChanged(e) {
        try {
            if (this.search) {
                window.location.hash = `#/orders?page=${e.target.value}&search=${encodeURIComponent(this.search || "")}`;
            } else {
                window.location.hash = `#/orders?page=${e.target.value}`;
            }
        } catch (e) {}
    }

    async refresh() {
        if (Login.hasGroup("OrderManager") || Login.hasGroup("Admin")) {
            const paged = await Subscription.paginate(this.sort, this.page, this.search);
            console.log(paged);
            this.lastTotal = paged.total;
            this.lastPerPage = paged.perPage;

            const items = [];

            let key = 0;
            paged.items.forEach((i) => {
                this.lookup[i.id.split("_")[2]] = i;
                items.push(<SubscriptionListItem key={`order-${key}`} order={i} search={this.search} page={this.page} />);
                ++key;
            });

            if (paged.items.length === 0) {
                this.errorMessage = "No Orders Found";
            } else {
                this.errorMessage = "Loading";
            }

            this.items = items;
        }
    }

    async render() {
        if (!Login.hasGroup("OrderManager") && !Login.hasGroup("Admin")) return null;
        if (this.edit && this.lookup[this.edit]) {
            return <SubscriptionDetailsView order={this.lookup[this.edit]} />;
        }

        return (
            <div class="flex-fill d-flex flex-column allow-scroll-y relative" style="margin-top: 64px;" key="view">
                <div class="d-flex fixed-header align-items-center flex-row p-3 bg-dark material-shadow text-white">
                    <div class="vw-2 pointer p-2" id="timestampCreated" onclick={this.onSort}>
                        Created
                        <i class="fas fa-sort-up p-2" style={this.sort.by === "timestampCreated" && this.sort.dir === 1 ? "" : "display: none;"}></i>
                        <i
                            class="fas fa-sort-down p-2"
                            style={this.sort.by === "timestampCreated" && this.sort.dir === 0 ? "" : "display: none;"}></i>
                    </div>
                    <div class="vw-2 pointer p-2" id="email" onclick={this.onSort}>
                        Email
                        <i class="fas fa-sort-up p-2" style={this.sort.by === "email" && this.sort.dir === 1 ? "" : "display: none;"}></i>
                        <i class="fas fa-sort-down p-2" style={this.sort.by === "email" && this.sort.dir === 0 ? "" : "display: none;"}></i>
                    </div>
                    <div class="vw-2 p-2">Order ID</div>
                    <div class="vw-2 p-2">Order Short ID</div>
                    <div class="vw-2 pointer p-2" id="state" onclick={this.onSort}>
                        Status
                        <i class="fas fa-sort-up p-2" style={this.sort.by === "state" && this.sort.dir === 1 ? "" : "display: none;"}></i>
                        <i class="fas fa-sort-down p-2" style={this.sort.by === "state" && this.sort.dir === 0 ? "" : "display: none;"}></i>
                    </div>
                    <div class="vw-2">
                        <MyndInput
                            id="search"
                            value={this.search}
                            placeholder="search..."
                            type="text"
                            class="material-input-light"
                            oninput={this.onSearchInput}
                            handleOnClear={this.onSearchClear}
                        />
                    </div>
                </div>
                <div class="relative">
                    <ListView noItemsMessage={this.errorMessage} items={this.items} />
                </div>
                <SubscriptionsPager
                    onPageChanged={this.onPageChanged}
                    onNext={this.onNext}
                    onPrevious={this.onPrevious}
                    page={this.page}
                    lastPerPage={this.lastPerPage}
                    lastTotal={this.lastTotal}
                />
            </div>
        );
    }
}
