"use strict";

export class Spinner extends Component {
    async render() {
        return (
            <div class="display-5">
                <i class="fas fa-spinner spin"></i>
            </div>
        );
    }
}

export class ListView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onScroll = this.onScroll.bind(this);
    }

    async componentDidMount() {
        this.scrollArea = this.parentNode;
        this.scrollArea.addEventListener("scroll", this.onScroll, false);
    }

    async componentWillUnmount() {
        this.scrollArea.removeEventListener("scroll", this.onScroll, false);
    }

    onScroll() {
        if (this.scrollArea.scrollTop >= this.scrollArea.scrollHeight - (this.maxHeight || 480 * 2)) {
            if (this.onNext) {
                this.onNext();
            }
        }

        if (this.onscroll) {
            this.onscroll();
        }
    }

    async render() {
        if (this.items.length === 0) {
            return (
                <div class="d-flex flex-fill justify-content-center text-center">
                    <div class="text-muted p-4">{this.noItemsMessage}</div>
                </div>
            );
        } else {
            return <div class="d-flex flex-column">{this.items}</div>;
        }
    }
}

export class ListViewGrid extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onScroll = this.onScroll.bind(this);
    }

    async componentDidMount() {
        this.scrollArea = this.parentNode;
        this.scrollArea.addEventListener("scroll", this.onScroll, false);
    }

    async componentWillUnmount() {
        this.scrollArea.removeEventListener("scroll", this.onScroll, false);
    }

    onScroll() {
        if (this.scrollArea.scrollTop >= this.scrollArea.scrollHeight - (this.maxHeight || 480 * 2)) {
            if (this.onNext) {
                this.onNext();
            }
        }

        if (this.onscroll) {
            this.onscroll();
        }
    }

    async render() {
        if (this.items.length === 0) {
            return (
                <div class="d-flex flex-fill justify-content-center text-center">
                    <div class="text-muted p-4">{this.noItemsMessage}</div>
                </div>
            );
        } else {
            return <div class="d-flex flex-fill flex-wrap align-content-start">{this.items}</div>;
        }
    }
}

export class MyndOption extends Component {
    async render() {
        return (
            <option value={this.value} selected={this.selected}>
                {this.text}
            </option>
        );
    }
}

export class MyndSelect extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            showPlaceholder: this.value && this.value.length > 0,
        });
        this.onRef = this.onRef.bind(this);
        this.onChange = this.onChange.bind(this);
        this.input = null;
    }

    onRef(f) {
        this.input = f;
        if (this.iref) this.iref(f);
    }

    onChange(e) {
        this.disabled = true;
        this.value = e.target.value;
        this.disabled = false;

        if (this.onchange) {
            this.onchange(e, this.value);
        }
    }

    async render() {
        return (
            <div class="form-group relative">
                <div style="top: -8pt;" class="text-purple abs fade-active text-left">
                    {this.placeholder}
                </div>
                <select
                    class="material-input"
                    ref={this.onRef}
                    id={this.id}
                    onchange={this.onChange}
                    placeholder={this.placeholder}
                    value={this.value}>
                    {this.items}
                </select>
            </div>
        );
    }
}

export class MyndButton extends Component {
    async render() {
        return (
            <button
                class={
                    "btn mt-2 " +
                    (this.type ? `btn-${this.type} ` : "btn-primary ") +
                    (this.noShadow ? "" : "material-shadow ") +
                    (this.class ? this.class : "")
                }
                style={this.style}
                onclick={this.onclick}>
                {this.text || this.children}
            </button>
        );
    }
}

export class MyndDialog extends Component {
    async render() {
        return (
            <div class="modal">
                <div class="modal-content">{this.children}</div>
            </div>
        );
    }
}

export class MyndInputErrorText extends Component {
    async render() {
        return <small class={"invalid text-danger ml-1 mt-1 " + (this.force ? "invalid-force" : "")}>{this.children}</small>;
    }
}

export class MyndFloatingButton extends Component {
    async render() {
        const staticStyle = "position: fixed; bottom: 20px; right: 20px; padding: 32px; width: auto; height: auto; z-index: 100; ";
        return (
            <MyndButton
                class={this.class ? this.class + " round-button" : "round-button"}
                style={this.style ? staticStyle + this.style : staticStyle}
                onclick={this.onclick}>
                <i class={this.icon} style="font-size: 24pt;" />
            </MyndButton>
        );
    }
}

export class MyndCheckbox extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.disabled = true;
        this.checked = e.target.checked;
        this.disabled = false;

        if (this.onchange) {
            this.onchange(this.checked, this.data);
        }
    }

    async render() {
        return (
            <div class="relative d-flex align-items-center p-2" style={this.style}>
                <input type="checkbox" checked={this.checked} id={this.id} ref={this.iref} value={this.value} onchange={this.onChange} />
                <label class="ml-2 text-left font-size: 22pt;" style="margin-bottom: 0px; font-size: 10pt;" for={this.id}>
                    {this.placeholder}
                </label>
                <i style="font-size: 12pt;" class={"ml-2 fas fa-info-circle " + (this.alt ? "" : "hidden")} title={this.alt} alt={this.alt} />
            </div>
        );
    }
}

export class Tag extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.onremove) {
            this.onremove(this.value);
        }
    }

    async render() {
        return (
            <div class="pill ml-2 mt-2 d-flex align-items-center text-primary bg-white material-shadow" style="max-height: 32px;">
                <small style="margin: 0px;" class="p-2">
                    {this.value}
                </small>
                <MyndButton
                    noShadow={true}
                    type="light"
                    class={"round-button " + (this.editable ? "" : "hidden")}
                    onclick={this.onDelete}
                    style="margin: 0px 0px 0px 4px !important; height:32px;">
                    <i class="fas fa-times" style="font-size: 8pt; margin: 0px; display: block;"></i>
                </MyndButton>
            </div>
        );
    }
}

export class TagList extends Component {
    async render() {
        const tags = [];

        for (let i = 0; i < this.tags.length; i++) {
            tags.push(<Tag editable={false} value={this.tags[i]} />);
        }

        return <div class="d-flex flex-wrap">{tags}</div>;
    }
}

export class TagInput extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.onTagRemove = this.onTagRemove.bind(this);
        this.onKeypress = this.onKeypress.bind(this);
        this.onInput = this.onInput.bind(this);

        this.inputValue = "";

        this.input = null;

        if (!this.tags) {
            this.observe({
                tags: [],
            });
        }
    }

    onKeypress(e) {
        if (e.key === "Enter" || e.key === ",") {
            e.preventDefault();
            e.stopPropagation();

            if (this.inputValue.match(/^[A-Za-z0-9 \-'_]+$/gi)) {
                this.tags.push(this.inputValue.toLowerCase());
                this.inputValue = "";

                this.input.clear();

                this.forceUpdate();

                if (this.onchange) {
                    this.onchange(this.tags);
                }

                setTimeout(() => {
                    if (this.input) {
                        this.input.focus();
                    }
                }, 2);
            }
        }
    }

    //stub to allow the clear button to show on input
    onClear() {}

    onInput(e) {
        this.inputValue = e.target.value;
    }

    onTagRemove(value) {
        const idx = this.tags.indexOf(value);

        if (idx > -1) {
            this.tags.splice(idx, 1);
            this.forceUpdate();

            if (this.onchange) {
                this.onchange(this.tags);
            }

            setTimeout(() => {
                if (this.input) {
                    this.input.focus();
                }
            }, 2);
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            if (this.input) {
                this.input.focus();
            }
        }, 1);
    }

    async render() {
        const content = [];

        for (let i = 0; i < this.tags.length; i++) {
            content.push(<Tag editable={true} value={this.tags[i]} onremove={this.onTagRemove} />);
        }

        content.push(
            <MyndInput
                ref={(f) => (this.input = f)}
                style="margin-left: 10px; width: 125px;"
                value={this.inputValue}
                hidePlaceholder={true}
                placeholder="keyword"
                pattern={`^[A-Za-z0-9 \'_\\-]+$`}
                required={true}
                oninput={this.onInput}
                onkeypress={this.onKeypress}
                handleOnClear={this.onClear}
            />
        );

        return <div class="d-flex flex-wrap">{content}</div>;
    }
}

export class MyndInput extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            showPlaceholder: this.value && this.value.length > 0,
        });
        this.onRef = this.onRef.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onInput = this.onInput.bind(this);
        this.input = null;
    }

    onRef(f) {
        this.input = f;
        if (this.iref) this.iref(f);
    }

    focus() {
        this.input.focus();
    }

    clear() {
        this.disabled = true;
        this.value = "";
        this.input.value = "";
        this.disabled = false;
        this.showPlaceholder = false;
    }

    onClear(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.handleOnClear) {
            this.handleOnClear();
        }

        this.disabled = true;
        this.value = null;
        this.input.value = null;
        this.disabled = false;

        this.showPlaceholder = false;
    }

    onInput(e) {
        this.disabled = true;
        this.value = e.target.value;
        this.disabled = false;

        if (this.oninput) {
            this.oninput(e);
        }

        if (this.value && this.value.length > 0 && !this.showPlaceholder) {
            this.showPlaceholder = true;
        } else if ((!this.value || !this.value.length) && this.showPlaceholder) {
            this.showPlaceholder = false;
        }
    }

    async render() {
        return (
            <div class="form-group relative" style={this.style}>
                <div
                    style="top: -8pt;"
                    class={
                        (this.showPlaceholder && !this.hidePlaceholder) || (!this.hidePlaceholder && this.value)
                            ? "abs text-purple fade-active text-left font-size: 22pt; " + (this.titleClass ? this.titleClass : "")
                            : "abs fade-inactive text-left font-size: 22pt; " + (this.titleClass ? this.titleClass : "")
                    }>
                    {this.placeholder}
                </div>
                <input
                    name={this.name}
                    class={"material-input " + (this.class ? this.class : "")}
                    ref={this.onRef}
                    pattern={this.pattern}
                    id={this.id}
                    type={this.type}
                    onchange={this.onchange}
                    required={this.required}
                    placeholder={this.placeholder}
                    oninput={this.onInput}
                    onkeypress={this.onkeypress}
                    onkeyup={this.onkeyup}
                    onkeydown={this.onkeydown}
                    value={this.value}
                    min={this.min}
                    max={this.max}
                />
                <MyndInputErrorText force={this.forceError}>{this.error}</MyndInputErrorText>
                <i
                    class="abs-br fas fa-times-circle p-1 text-black pointer"
                    style={this.showPlaceholder && this.handleOnClear ? "right: 8px; bottom: 1.5rem;" : "display: none;"}
                    onclick={this.onClear}
                />
            </div>
        );
    }
}

export class MyndPage extends Component {
    async render() {
        if (this.focusFirstInput) {
            setTimeout(() => {
                let child = this.child;

                if (this.base) {
                    const inp = this.base.querySelector("input");
                    if (inp) {
                        inp.focus();
                        return;
                    }
                }

                while (child) {
                    if (child.base) {
                        const inp = child.base.querySelector("input");
                        if (inp) {
                            inp.focus();
                            break;
                        }
                    }

                    child = child.child;
                }
            }, 100);
        }

        return (
            <div class={this.show ? this.enter : this.exit} style={this.style}>
                {this.children}
            </div>
        );
    }
}

export class FilterCategory extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onChange = this.onChange.bind(this);

        this.selected = [];
    }

    onChange(checked, value) {
        value.checked = checked;

        if (checked) {
            this.selected.push(value);
        } else {
            const idx = this.selected.indexOf(value);
            if (idx > -1) {
                this.selected.splice(idx, 1);
            }
        }

        if (this.onchange) {
            const filters = [];

            this.selected.forEach((f) => {
                filters.push({
                    value: f.value,
                    min: f.min,
                    max: f.max,
                    type: this.category.type,
                });
            });

            this.onchange(this.category, filters);
        }
    }

    async componentDidMount() {
        this.category.checkbox.forEach((c) => {
            if (c.checked) {
                this.selected.push(c);
            }
        });

        if (this.onchange) {
            const filters = [];

            if (this.selected.length > 0) {
                this.selected.forEach((f) => {
                    filters.push({
                        value: f.value,
                        min: f.min,
                        max: f.max,
                        type: this.category.type,
                    });
                });

                this.onchange(this.category, filters);
            }
        }
    }

    async render() {
        const items = [];

        this.category.checkbox.forEach((c) => {
            items.push(
                <MyndCheckbox
                    style="margin-left: 5px; min-width: 125px;"
                    data={c}
                    onchange={this.onChange}
                    placeholder={c.value}
                    alt={c.desc}
                    checked={c.checked}
                />
            );
        });

        return (
            <div class="filter-category w-100 d-flex flex-column p-2">
                <div class="d-flex flex-row align-items-center">
                    <i class={this.category.icon} style="font-size: 16pt;"></i>
                    <h3 class="ml-2">{this.category.title}</h3>
                </div>
                <div class="d-flex flex-row align-items-center flex-wrap">{items}</div>
            </div>
        );
    }
}

export class MultiCheckboxSelector extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            checkboxes: [],
        });
        this.checkAll = false;

        this.onChange = this.onChange.bind(this);
        this.onCheckAll = this.onCheckAll.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.results = {};

        this.errorMessage = "Loading";
    }

    onSubmit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.onsubmit) {
            this.onsubmit(this.results);
        }
    }

    onChange(checked, v) {
        this.results[v] = checked;
    }

    onCheckAll(checked) {
        const wasChecked = this.checkAll;
        this.checkAll = checked;
        this.refresh(wasChecked);
    }

    async componentDidMount() {
        for (let n in this.items) {
            this.results[n] = this.items[n];
        }
        this.refresh();
    }

    async refresh(wasChecked) {
        const list = [];
        for (let n in this.items) {
            if (this.checkAll) {
                this.results[n] = true;
            } else if (wasChecked && !this.checkAll) {
                this.results[n] = false;
            }

            list.push(<MyndCheckbox data={n} onchange={this.onChange} checked={this.results[n]} placeholder={n} />);
        }

        this.checkboxes = list;
    }

    async render() {
        return (
            <div
                class="text-dark abs-tr d-flex flex-column min-width-256 max-width-256 bg-white material-shadow mt-2 mr-2"
                style={"z-index: 100;" + this.style}>
                <div class="form-group p-2" style="height: 24px;">
                    <MyndCheckbox onchange={this.onCheckAll} placeholder="Select All" data={null} checked={this.checkAll} />
                </div>
                <div class="p-2 allow-scroll-y relative" style="height: 240px;">
                    <ListView items={this.checkboxes} noItemsMessage={this.errorMessage} />
                </div>
                <div class="form-group d-flex justify-content-between p-2" style="margin-bottom: 0px;">
                    <MyndButton type="light" onclick={this.oncancel} text={this.cancelTitle} style="height: 42px;" />
                    <MyndButton onclick={this.onSubmit} text={this.submitTitle} style="height: 42px;" />
                </div>
                <div
                    class={"abs bg-white d-flex flex-column align-items-center justify-content-center " + (this.saving ? "" : "hidden")}
                    style="bottom: 0px; right:0px;">
                    <h4>Applying Changes</h4>
                    <i class="fas fa-spinner spin" style="font-size: 32pt;" />
                </div>
            </div>
        );
    }
}
