'use strict';

import { MyndButton, MyndCheckbox, ListView, ListViewGrid, MyndInput, MyndFloatingButton, MyndOption, MyndSelect } from "./common";
import {INDEX_TO_DEVICE_TYPE} from '../logic/constants';
import {CommunitiesListSelect} from './communities';
import {Littlstar} from '../logic/littlstar';
import Device from '../logic/device';
import {Login} from '../logic/login';

class DeviceDownloadListItem extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.content = null;
        this.observe({
            showDescription: false,
            isVisible: false,
            image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
        });
        this.onScroll = this.onScroll.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.div = null;
    }

    get keywords() {
        return this.video.keywords;
    }

    get imageBanner() {
        const banners = this.video.banners;
        const posters = this.video.posters;

        if (banners == null) {
            return posters.mobile;
        } else {
            if (banners.medium) {
                return banners.medium;
            } else if (banners.small) {
                return banners.small;
            } else {
                return posters.mobile;
            }
        }
    }

    get duration() {
        const min = Math.floor(this.video.duration / 60);
        const secs = Math.floor(this.video.duration % 60);

        let timeText = '';

        if (min < 10) {
            timeText += '0' + min;
        } else {
            timeText += min;
        }

        timeText += ':';

        if (secs < 10) {
            timeText += '0' + secs;
        } else {
            timeText += secs;
        }

        return timeText;
    }

    get description() {
        if (this.video.myndDescription) {
            return this.video.myndDescription;
        }

        return this.video.description;
    }

    get experienceLevel() {
        let tags = [];

        if (this.video.tags.length > 0) {
            tags = this.video.tags;
        } else {
            tags = this.keywords;
        }

        let xpLevel = 'Pilgrim';

        if (tags.indexOf('explorer') > -1) {
            xpLevel = 'Explorer';
        } else if (tags.indexOf('adventurer') > -1) {
            xpLevel = 'Adventurer';
        } else if (tags.indexOf('pathfinder') > -1) {
            xpLevel = 'Pathfinder';
        }

        return xpLevel;
    }

    onScroll(e) {
        if (!this.isVisible) {
            if (this.isInViewport()) {
                this.isVisible = true;

                if (this.view && this.view.base) {
                    this.view.base.removeEventListener('scroll', this.onScroll, false);
                }

                window.removeEventListener('resize', this.onScroll, false);
            }
        }
    }

    isInViewport() {
        if (!this.div) return false;

        var rect = this.div.getBoundingClientRect();

        return (
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    onRemoveClick(e) {
        e.stopPropagation();
        e.preventDefault();

		let title = this.video.title;

        if (this.video.myndTitle && this.video.myndTitle.length > 0) {
            title = this.video.myndTitle;
        }
		
        const doRemove = confirm(`Remove ${title} from device?`);
        if (!doRemove) {
			return;
        }
		this.remove();
    }

    async componentDidMount() {
        if (this.view && this.view.base) {
            this.view.base.addEventListener('scroll', this.onScroll, false);
        }
        window.addEventListener('resize', this.onScroll, false);
        setTimeout(() => {
            this.onScroll();
        }, 1);
    }

    async componentWillUnmount() {
        if (this.view && this.view.base) {
            this.view.base.removeEventListener('scroll', this.onScroll, false);
        }
        window.removeEventListener('resize', this.onScroll, false);
    }

    async remove() {
        try {
            await this.device.removeDownload(this.video.slug);
        } catch (e) {
            console.log(e);
        }
    }

    async render() {
        let tags = [];

        if (this.video.tags.length > 0) {
            tags = this.video.tags;
        } else {
            tags = this.keywords;
        }

        this.isHeight = tags.indexOf('heights') > -1;

        let title = this.video.title;

        if (this.video.myndTitle && this.video.myndTitle.length > 0) {
            title = this.video.myndTitle;
        }

        let xpLevel = 'Pilgrim';
        let motion = 'Stationary';

        const isFlying = tags.indexOf('flying') > -1;
        const isSmallSpace = tags.indexOf('small spaces') > -1;
        const isPersonalSpace = tags.indexOf('personal space') > -1;
        const isInteractive = tags.indexOf('interactive') > -1;
        const isDistressingAudio = tags.indexOf('distressing audio') > -1;
        const isFlashingLights = tags.indexOf('flashing lights') > -1;
        const isWater = tags.indexOf('water') > -1;
        const isSpiders = tags.indexOf('spiders') > -1 || tags.indexOf('big bugs') > -1;

        if (tags.indexOf('mostly stationary') > -1) {
            motion = 'Mostly Stationary';
        } else if (tags.indexOf('mixed') > -1) {
            motion = 'Mixed';
        } else if (tags.indexOf('moving') > -1) {
            motion = 'Moving';
        }

        xpLevel = this.experienceLevel;

        const content = [
            <h4>{Littlstar.getCatById(this.video.category).name}</h4>,
            <h3>{title}</h3>,
            <div class='d-flex flex-column'>
                <p cclass='abs-tr' style='right: 45px; top: 25px;'>
                    <strong>Duration:</strong> {this.duration}
                </p>
                <p>{this.description}</p>
                <div class='d-flex flex-row flex-wrap'>
                    <small class='mt-1 ml-4'>
                        <strong>Experience Level:</strong> {xpLevel}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Interactive:</strong> {isInteractive ? 'Yes' : 'No'}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Motion:</strong> {motion}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Heights:</strong> {this.isHeight ? 'Yes' : 'No'}
                        <i class='fas fa-exclamation-triangle height-warning ml-2'
                            alt='This video contains heights'
                            title='This video contains heights'
                            style={this.isHeight ? 'display: inline-block' : 'display: none;'}></i>
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Flashing Lights:</strong> {isFlashingLights ? 'Yes' : 'No'}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Personal Space:</strong> {isPersonalSpace ? 'Yes' : 'No'}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Small Spaces:</strong> {isSmallSpace ? 'Yes' : 'No'}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Water / Underwater:</strong> {isWater ? 'Yes' : 'No'}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Spiders / Big bugs:</strong> {isSpiders ? 'Yes' : 'No'}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Distressing Audio:</strong> {isDistressingAudio ? 'Yes' : 'No'}
                    </small>
                    <small class='mt-1 ml-4'>
                        <strong>Flying:</strong> {isFlying ? 'Yes' : 'No'}
                    </small>
                </div>
            </div>,
            <div class='d-flex flex-wrap align-items-center mt-2'>
                <p class='text-wrap' style='font-size: 9pt;'>
                    {tags.join(', ')}
                </p>
            </div>
        ];

        const imageCard = (<div 
                ref={f => this.div = f}
                class='video-image mr-1'
                style={`background-image: url("${this.isVisible ? this.imageBanner : this.image}");`}>
                    <div class='d-flex flex-column video-image-info justify-content-end' style='height: 100%;'>
                        <MyndButton onclick={this.onRemoveClick} type='danger w-100' style='height: 64px;'>
                            REMOVE FROM DEVICE
                        </MyndButton>
                    </div>
                </div>
            );

        return (
            <div class='d-flex video-item ml-auto mr-auto'>
                <div class='d-flex flex-row flex-fill'>
                    {imageCard}
                    <div class='d-flex flex-fill video-info flex-column text-white p-4'>
                        {content}
                    </div>   
                </div> 
            </div>
        );
    }
}

export class MultiDeviceSelector extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            items: []
        });
        this.checkAll = false;
        this.selected = [];
        this.selectedImeis = [];

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCheckAll = this.onCheckAll.bind(this);
        this.errorMessage = 'Loading';
    }

    onSubmit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.onSelect) {
            this.onSelect(this.selected);
        }
    }

    onCheckAll(checked) {
        this.checkAll = checked;
        this.refresh();
    }

    onCancel(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.oncancel) {
            this.oncancel(e);
        }
    }

    onChange(checked, v) {
        if (checked) {
            this.selected.push(v);
            this.selectedImeis.push(v.imei);
        } else {
            const idx = this.selected.indexOf(v);

            if (idx > -1) {
                this.selected.splice(idx, 1);
            }

            const idx2 = this.selectedImeis.indexOf(v.imei);

            if (idx2 > -1) {
                this.selectedImeis.splice(idx2, 1);
            }
        }
    }

    async componentDidMount() {
        this.refresh();
    }

    async refresh() {
        const items = [];
        this.selected = [];

        if (Login.hasGroup('Admin')) {
            try {
                console.log('refreshing device selector list admin');
                let available = (await Device.all()).items;
                this.errorMessage = 'No devices found';

                available.sort((a, b) => {
                    if (a.deviceName < b.deviceName) {
                        return -1;
                    } else if (a.deviceName > b.deviceName) {
                        return 1;
                    }

                    return 0;
                });

                let key = 0;
                available.forEach(i => {
                    const item = new Device(i);

                    if (item.type == 0 || this.showAll) {
                        if (this.checkAll || this.selectedImeis.indexOf(item.imei) > -1) {
                            this.selected.push(item);
                        }

                        items.push(<MyndCheckbox key={`device-box-${key}`}
                            data={item}
                            onchange={this.onChange}
                            checked={this.checkAll}
                            placeholder={item.name} />
                        );
                        ++key;
                    }
                });
            } catch (e) {
                console.log(e);
            }
        } else {
            try {
                let available = await Device.allCommunity();
                this.errorMessage = 'No devices found';

                available.sort((a, b) => {
                    if (a.deviceName < b.deviceName) {
                        return -1;
                    } else if (a.deviceName > b.deviceName) {
                        return 1;
                    }

                    return 0;
                });

                let key = 0;
                available.forEach(i => {
                    const item = new Device(i);

                    if (item.type == 0 || this.showAll) {
                        if (this.checkAll || this.selectedImeis.indexOf(item.imei) > -1) {
                            this.selected.push(item);
                        }

                        items.push(<MyndCheckbox key={`device-box-${key}`}
                            data={item}
                            onchange={this.onChange}
                            checked={this.checkAll}
                            placeholder={item.name} />
                        );
                        ++key;
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }

        this.items = items;
    }

    async render() {
        return (
            <div class='text-dark abs-tr d-flex flex-column min-width-256 max-width-256 bg-white material-shadow mt-2 mr-2'
                style={'z-index: 100;' + this.style}>
                <div class='form-group p-2' style='height: 24px;'>
                    <MyndCheckbox onchange={this.onCheckAll}
                        placeholder='Select All'
                        data={null}
                        checked={this.checkAll} />
                </div>
                <div class='p-2 allow-scroll-y relative' style='height: 240px;'>
                    <ListView items={this.items}
                        noItemsMessage={this.errorMessage} />
                </div>
                <div class='form-group d-flex justify-content-between p-2' style='margin-bottom: 0px;'>
                    <MyndButton type='light'
                        onclick={this.onCancel}
                        text={this.cancelTitle}
                        style='height: 42px;' />
                    <MyndButton onclick={this.onSubmit}
                        text={this.submitTitle}
                        style='height: 42px' />
                </div>
                <div class={'abs bg-white d-flex flex-column align-items-center justify-content-center ' + (this.saving ? '' : 'hidden')}
                    style='bottom: 0px; right:0px;'>
                    <h4>Applying Changes</h4>
                    <i class='fas fa-spinner spin' style='font-size: 32pt;'></i>
                </div>
            </div>
        );
    }
}

class DeviceDownloadsView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            downloads: []
        });
        this.onDeviceStatusUpdate = this.onDeviceStatusUpdate.bind(this);
        this.errorMessage = 'Loading';
    }

    onDeviceStatusUpdate() {
        this.refresh();
    }

    async refresh() {
        //go ahead and cache all videos
        try {
            await Littlstar.all
        } catch (e) {
            console.log(e);
        }

        const videos = [];
        const downloads = this.device.downloads;
        const sorter = [];

        for (let id in downloads) {
            const d = downloads[id];

            if (d.active) {
                try {
                    const vid = await Littlstar.getVideoBySlug(id);

                    if (vid) {
                        sorter.push(vid);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }

        sorter.sort((a, b) => {
            const cata = Littlstar.getCatById(a.category);
            const catb = Littlstar.getCatById(b.category);
            if (cata.name < catb.name) {
                return -1;
            } else if (cata.name > catb.name) {
                return 1;
            } else {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                }
            }

            return 0;
        });

        let category = null;
        sorter.forEach(v => {
            const cat = Littlstar.getCatById(v.category);

            if (cat.name !== category) {
                videos.push(<h2 class='display-6 w-100 ml-2 mr-2 mt-4 p-4 text-white bg-dark rounded'>{cat.name}</h2>);
                category = cat.name;
            }

            videos.push(<DeviceDownloadListItem
                video={v}
                view={this.view}
                device={this.device} />
            );
        });

        this.errorMessage = 'No videos on device';
        this.downloads = videos;
    }

    async componentDidMount() {
        if (this.device) {
            this.device.on('status', this.onDeviceStatusUpdate);
        }
    }

    async componentWillUnmount() {
        if (this.device) {
            this.device.removeListener('status', this.onDeviceStatusUpdate);
        }
    }

    async render() {
        return (
            <div class='device-status-videos relative mt-5 p-4' style='min-height: 512px;'>
                <h1 class='display-4 text-center'>Videos on Device</h1>
                <ListViewGrid noItemsMessage={this.errorMessage} items={this.downloads} />
            </div>
        )
    }
}

class DeviceStatusView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.online = false;
        this.batteryLevel = 0;
        this.totalSpace = 0;
        this.freeSpace = 0;
        this.lastOnline = new Date(2018, 1, 1);
    }

    get batteryPercent() {
        const p = Math.round(this.batteryLevel * 100);

        if (Number.isNaN(p)) {
            return 0;
        }

        return p;
    }

    get spacePercent() {
        const p = Math.round((this.totalSpace - this.freeSpace) / this.totalSpace * 100);

        if (Number.isNaN(p)) {
            return 0;
        }

        return p;
    }

    async componentDidMount() {
        this.refresh();
    }

    async refresh() {
        try {
            await this.item.status();
        } catch (e) {

        }

        this.online = this.item.online;
        this.batteryLevel = this.item.batteryLevel;
        this.totalSpace = this.item.totalSpace;
        this.freeSpace = this.item.freeSpace;
        this.lastOnline = new Date(this.item.lastOnline);

        this.forceUpdate();
    }

    async render() {
        return (
            <div class='form-group w-100 bg-white rounded p-4'>
                <h2 style='margin-top: 0px !important;'>Status</h2>
                <div>Last Online: {this.lastOnline.toLocaleString()}</div>
                <div>Online: {this.online ? 'Yes' : 'No'}</div>
                <h6 class='mt-2'>
                    Battery Level: {this.batteryPercent}%
                </h6>
                <div class='progress'>
                    <div class='progress-bar' style={`width:${this.batteryPercent}%;`} role='progressbar'
                        aria-valuenow={this.batteryPercent}
                        aria-valuemin='0'
                        aria-valuemax='100' />
                </div>
                <h6 class='mt-2'>
                    Storage Used: {this.spacePercent}%
                </h6>
                <div class='progress'>
                    <div class='progress-bar' style={`width:${this.spacePercent}%;`} role='progressbar'
                        aria-valuenow={this.spacePercent}
                        aria-valuemin='0'
                        aria-valuemax='100' />
                </div>
            </div>
        );
    }
}

class DeviceEditView extends Component {
    constructor(o, parent) {
        super(o, parent);

        this.onNameInput = this.onNameInput.bind(this);
        this.onSubscriptionEmailInput = this.onSubscriptionEmailInput.bind(this);
        this.onCommunitySelect = this.onCommunitySelect.bind(this);
        this.onDeviceTypeSelect = this.onDeviceTypeSelect.bind(this);

        this.onClick = this.onClick.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.inputTimeout = null;
        this.input = null;

        this.originalName = this.item.name;
        this.originalSubscriptionEmail = this.item.subscriptionEmail
        this.originalCommunity = this.item.community;
        this.originalType = this.item.type;
    }

    async onDelete(e) {
        e.stopPropagation();
        e.preventDefault();

		const doRemove = confirm(`Delete ${this.originalName}?`);

		if (!doRemove) {
			return;
		}
        
        location.hash = `#/devices?community=${encodeURIComponent(this.item.community)}`;
        
		const succ = await this.remove();

		if (this.parent) {
			await this.parent.refresh();
		}
    }

    async remove() {
        try {
            return await this.item.remove();
        } catch (e) {
            console.log(e);
        }

        return false;
    }

    async onClick(e) {
        e.stopPropagation();
        e.preventDefault();

        await this.save();

        location.hash = `#/devices?community=${encodeURIComponent(this.item.community)}`;
    }

    async save() {
        if (Login.hasGroup('Admin')) {
            try {
                if (this.originalType != this.item.type) {
                    await this.item.updateType(this.item.type);
                    this.originalType = this.item.type;
                }
            } catch (e) {
                console.log(e);
            }

            try {
                if (this.originalCommunity !== this.item.community) {
                    await this.item.updateCommunity(this.item.community);
                    this.originalCommunity = this.item.community;
                }
            } catch (e) {
                console.log(e);
            }

            try {
                if (this.originalName !== this.item.name) {
                    await this.item.updateName(this.item.name);
                    this.originalName = this.item.name;
                }
            } catch (e) {
                console.log(e);
            }

            try {
                if (this.originalSubscriptionEmail !== this.item.subscriptionEmail) {
                    await this.item.updateSubscriptionEmail(this.item.subscriptionEmail);
                    this.originalSubscriptionEmail = this.item.subscriptionEmail;
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    onCommunitySelect(value) {
        this.item.community = value;
    }

    onDeviceTypeSelect(e, value) {
        this.item.type = parseInt(value);
    }

    onNameInput(e) {
        this.item.name = e.target.value;

        if (this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }

        this.inputTimeout = setTimeout(() => {
            this.save();
        }, 500);
    }

    onSubscriptionEmailInput(e) {
        this.item.subscriptionEmail = e.target.value;

        if (this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }

        this.inputTimeout = setTimeout(() => {
            this.save();
        }, 500);
    }


    async render() {
        const inputContent = [];
        const buttons = [];
        let deviceType = (<p class='mt-2 ml-1'>Device Type: {INDEX_TO_DEVICE_TYPE[this.item.type]}</p>)

        if (Login.hasGroup('Admin')) {
            const types = [];

            for (let i = 0; i < 2; i++) {
                const selected = i === this.item.type ? 'selected' : null;
                types.push(<MyndOption 
                    value={i}
                    text={INDEX_TO_DEVICE_TYPE[i]}
                    selected={selected} />
                );
            }

            deviceType = (
                <MyndSelect 
                    onchange={this.onDeviceTypeSelect}
                    placeholder='device type'
                    value={this.item.type}
                    items={types} />
            );

            inputContent.push(<MyndInput 
                type='text'
                value={this.item.name}
                placeholder='device name'
                pattern={`^[A-Za-z0-9\\. \']+$`}
                required={true}
                oninput={this.onNameInput} />
            );
            inputContent.push(<CommunitiesListSelect
                onchange={this.onCommunitySelect}
                selected={this.item.community} />
            );
            
            inputContent.push(deviceType);

            inputContent.push(<MyndInput
                type='email'
                value={this.item.subscriptionEmail}
                placeholder='subscription email'
                oninput={this.onSubscriptionEmailInput} />
            );

            buttons.push(<MyndButton
                type='danger'
                onclick={this.onDelete}>DELETE</MyndButton>
            );
        }

        buttons.push(<MyndButton 
            onclick={this.onClick}>DONE</MyndButton>
        );

        return (
            <div class='device-status-area bg-light material-shadow min-width-512 max-width-512 ml-auto mr-auto'>
                <div class='p-4 bg-dark text-white rounded'>
                    <h1 class='display-5'>
                        {this.item.name}
                    </h1>
                    <div class='p-2 bg-white text-dark rounded'>
                        <h5 class='mt-2 ml-1'>
                            IMEI: {this.item.imei}
                        </h5>
                    </div>
                </div>
                <DeviceStatusView item={this.item} />
                <div class='mt-20 pl-4 pr-4'>
                    {inputContent}
                </div>
                <div class='d-flex w-100 justify-content-between pl-4 pr-4 pb-4'>
                    {buttons}
                </div>
            </div>
        );
    }
}

class DeviceAddView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.name = '';
        this.imei = '';
        this.community = this.community || '';
        this.deviceType = 0;

        this.observe({
            error: 'Invalid IMEI format'
        });
        this.forceError = false;
        this.onImeiInput = this.onImeiInput.bind(this);
        this.onNameInput = this.onNameInput.bind(this);
        this.onCommunitySelect = this.onCommunitySelect.bind(this);
        this.onDeviceTypeSelect = this.onDeviceTypeSelect.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.name.match(/^[A-Za-z0-9\. ']+$/gi) && this.imei.match(/^[A-Za-z0-9\-]+$/gi) && this.community.match(/^[A-Za-z0-9\. ']+$/gi)) {
            try {

                const device = new Device({
                    deviceName: this.name,
                    imei: this.imei,
                    community: this.community,
                    deviceType: this.deviceType
                });

                await device.add();

                if (this.onSubmission) {
                    this.onSubmission();
                }
            } catch (e) {
                this.forceError = true;
                this.error = 'IMEI already in use'
                console.log(e);
            }
        }
    }

    onDeviceTypeSelect(e, value) {
        this.deviceType = parseInt(value);
    }

    onCommunitySelect(value) {
        this.community = value;
    }

    onImeiInput(e) {
        this.imei = e.target.value;

        if (!this.imei.match(/^[A-Za-z0-9\-]+$/gi) && this.error !== 'Invalid IMEI format') {
            this.forceError = false;
            this.error = 'Invalid IMEI format';
        }
    }

    onNameInput(e) {
        this.name = e.target.value;
    }

    async render() {
        const types = [];

        for (let i = 0; i < 2; i++) {
            const selected = i === this.deviceType ? 'selected' : null;
            types.push(<MyndOption 
                value={i}
                text={INDEX_TO_DEVICE_TYPE[i]}
                selected={selected} />
            );
        }

        return (
            <div class='bg-white p-2 min-width-512 max-width-512 material-shadow' style='position: fixed; right: 64px; bottom: 64px;'>
                <MyndInput
                    type='text'
                    placeholder='IMEI'
                    pattern={`^[A-Za-z0-9\\-]+$`}
                    required={true}
                    value={this.imei}
                    oninput={this.onImeiInput}
                    error={this.error}
                    forceError={this.forceError} />
                <MyndInput
                    type='text'
                    placeholder='device name'
                    pattern={`^[A-Za-z0-9\\. \']+$`}
                    required={true}
                    value={this.name}
                    error='Invalid name format'
                    oninput={this.onNameInput} />
                <CommunitiesListSelect
                    onchange={this.onCommunitySelect}
                    selected={this.community} />
                <MyndSelect
                    onchange={this.onDeviceTypeSelect}
                    placeholder='device type'
                    value={this.deviceType}
                    items={types} />
                <MyndButton
                    onclick={this.onSubmit}>ADD</MyndButton>
            </div>
        );
    }
}

class DeviceListItem extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        e.stopPropagation();
        e.preventDefault();

        location.hash = `#/devices?edit=${encodeURIComponent(this.item.imei)}`;
    }

    async render() {
        return (
            <div class='list-item pointer'
                onclick={this.onClick}>
                <h3 class='subtitle'>{this.item.imei}</h3>
                <h2 class='title'>{this.item.name}</h2>
            </div>
        );
    }
}

class CommunityItem extends Component {
    constructor(o, parent) {
        super(o, parent);
    }

    async render() {
        return (
            <li class={'list-item-nav pointer ' + (this.selected ? 'active' : '')}>
                <a class='p-4' href={`#/devices?community=${encodeURIComponent(this.community)}`}>{this.community}</a>
            </li>
        );
    }
}

export default class DevicesView extends Component {
    constructor(o, parent) {
        super(o, parent);
        
        this.observe({
            cache: [],
            showAdd: false
        });

        this.onToggleAddClick = this.onToggleAddClick.bind(this);
        this.onSubmission = this.onSubmission.bind(this);
        this.onSearchInput = this.onSearchInput.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);

        this.errorMessage = 'Loading';
        
        this.search = '';
        this.searchTimeout = null;

        this.communityLookup = {};
        this.activeCommunityLookup = {};
        this.communityItems = [];
        this.items = {};
    }

    onToggleAddClick(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!this.showAdd) {
            this.showAdd = true;
        } else if (this.showAdd) {
            this.showAdd = false;
        }
    }

    onSearchClear(e) {
        this.search = '';

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            this.forceUpdate();
        }, 250);
    }

    onSearchInput(e) {
        this.search = e.target.value.toLowerCase();

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            this.forceUpdate();
        }, 250);
    }

    async componentDidMount() {
        this.refresh();
    }

    async onSubmission() {
        this.onToggleAddClick();
        this.refresh();
    }

    buildLookup(available) {
        let com = null;
        let deviceGroup = [];
        this.items = {};
        this.communityLookup = {};
        this.communityItems = [];

        available.forEach(i => {
            if (i.community !== com) {
                deviceGroup = [];
                com = i.community;
                this.communityItems.push(com);
                this.communityLookup[com] = deviceGroup;
            }

            const item = new Device(i);
            deviceGroup.push(item);
            this.items[item.imei] = item;
        });
    }

    build(available) {
        let com = null;
        this.communityItems = [];
        this.activeCommunityLookup = {};
        const communities = [];

        let key = 0;
        available.forEach(i => {
            if (this.search) {
                if (i.deviceName.toLowerCase().indexOf(this.search) === -1 && i.imei.toLowerCase().indexOf(this.search) === -1) {
                    return;
                }
            }

            if (i.community !== com) {
                const com2 = i.community;
                communities.push(<CommunityItem key={`community-${key}`} selected={this.community === com2 || (!com && !this.community)} community={com2} />);
                com = i.community;
                this.communityItems.push(com);
                this.activeCommunityLookup[com] = [].concat(this.communityLookup[com]);
                ++key;
            }
        });

        return communities;
    }

    async refresh() {
        if (Login.hasGroup('Admin')) {
            const available = (await Device.all()).items;
            this.buildLookup(available);
            this.cache = available;
        }
        else {
            const available = await Device.allCommunity();
            this.buildLookup(available);
            this.cache = available;
        }
    }

    async render() {
        let content = [];
        if (this.edit !== null && this.items[this.edit]) {

            try {
                content.push(<DeviceEditView
                    item={this.items[this.edit]} />
                );
                content.push(<DeviceDownloadsView
                    view={this}
                    device={this.items[this.edit]} />
                );

                return (
                    <div class='device-status w-100 flex-fill d-flex allow-scroll-y relative' style='margin-top: 64px;' key='view'>
                        {content}
                    </div>
                );
            } catch (e) {
                return (
                    <div class='w-100 flex-fill d-flex flex-column allow-scroll-y relative' style='margin-top: 64px;' key='view' />
                );
            }
        }

        let community = this.community;
        const communities = this.build(this.cache || []);

        if ((!community || !this.activeCommunityLookup[community]) && this.communityItems.length > 0) {
            community = this.communityItems[0];
        }

        const devices = community ? (this.activeCommunityLookup[community] || []) : []

        if (devices.length == 0) {
            this.errorMessage = 'No Devices'
        }
        else {
            this.errorMessage = 'Loading';
        }

        const deviceViews = [];

        let key = 0;
        for(let i = 0; i < devices.length; ++i) {
            const dev = devices[i];
            if (this.search) {
                if (dev.name.toLowerCase().indexOf(this.search) === -1 && dev.imei.toLowerCase().indexOf(this.search) === -1) {
                    continue;
                }
            }
            deviceViews.push(<DeviceListItem key={`device-${key}`} item={dev} />);
            ++key;
        }

        content.push(
            <div class='flex-fill d-flex allow-scroll-y relative flex-wrap'>
                <div class='d-flex flex-row w-100 relative justify-content-center bg-dark material-shadow align-items-center p-2 fixed-header' style='z-index: 100;'>
                    <MyndInput placeholder='search' titleClass='text-material-light' class='min-width-256 material-input-light' value={this.search} handleOnClear={this.onSearchClear} oninput={this.onSearchInput} type='text' />
                </div>
                <ul class='side-menu max-width-256 material-shadow allow-scroll-y' style='background-color: white; padding-left: 0px; margin-bottom: 0;'>
                    {communities}
                </ul>
                <div class='flex-fill'>
                    <div class='p-2'>
                        <ListView noItemsMessage={this.errorMessage} items={deviceViews} />
                    </div>
                </div>
            </div>
        );

        if (Login.hasGroup('Admin')) {
            content.push(<MyndFloatingButton icon='fas fa-plus' onclick={this.onToggleAddClick} />);
            if (this.showAdd) {
                content.push(<DeviceAddView community={this.community} onSubmission={this.onSubmission} />);
            }
        }

        return (
            <div class='w-100 flex-fill d-flex relative' style='margin-top: 64px;' key='view'>
                {content}
            </div>
        );
    }
}