'use strict';

import Axios from "axios";
import {Login} from "./login";

let API_URL = "https://dp2ov4dxt5.execute-api.us-east-2.amazonaws.com/Beta/apps";

if(!window.location.hostname.startsWith('portal.')) {
    API_URL = "https://ur53hwydvf.execute-api.us-east-2.amazonaws.com/Test/apps";
}

export default class Apps {
    static async update(bundle, name, build, description, vive, gear, pico, mirage, icon, tier, contr) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'update', 
                bundle: bundle, 
                name: name,
                build: build,
                description: description,
                vive: vive,
                gear: gear,
                pico: pico,
                mirage: mirage,
                icon: icon,
                tier: tier,
                controller: contr
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if(!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async create(bundle, name, description, vive, gear, pico, mirage, icon, tier) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL,JSON.stringify({
                type: 'create',
                bundle: bundle, 
                name: name,
                description: description,
                vive: vive,
                gear: gear,
                pico: pico,
                mirage: mirage,
                icon: icon,
                tier: tier
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static async remove(bundle) {
        try {
            const token = await Login.idToken;
            let result = await Axios.post(API_URL, JSON.stringify({
                type: 'remove', 
                bundle: bundle
            }), {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            });

            result = result.data;

            if (!result.success) {
                console.log(result.error);
                return false;
            }

            return true;
        }
        catch (e) {
            console.log(e.toString());
        }

        return false;
    }

    static get all() {
        return new Promise(async (res, rej) => {
            try {
                const token = await Login.idToken;
                let result = await Axios.post(API_URL, JSON.stringify({type: 'all'}),
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Authorization': token
                    }
                });

                result = result.data;

                if(!result.success) {
                    console.log(result.error);
                    return res([]);
                }

                return res(result.data);
            }
            catch (e) {
                console.log(e.toString());
            }

            return res([]);
        });
    }
}