'use strict';

/**
 * Simple lean and mean proper CSV parser
 */
export default class CSVParser {
    constructor(data) {
        this.data = data;
        this.rows = [];
        this.parse();
    }

    parse() {
        let inQuotes = false;
        let isEscaped = false;
        this.rows = [];

        let buffer = "";

        let row = [];

        for(let i = 0; i < this.data.length; i++) {
            const c = this.data[i];

            if(c === "\\" && inQuotes && !isEscaped) {
                isEscaped = true;
            }
            else if(c === "\"" && !inQuotes && !isEscaped) {
                inQuotes = true;
            }
            else if(c === "\"" && inQuotes && !isEscaped) {
                inQuotes = false;
            }
            else if(c === "," && !inQuotes) {
                row.push(buffer);
                buffer = "";
            }
            else if(c === "\r" && this.data[i+1] === "\n" && !inQuotes) {
                i++;
                row.push(buffer);
                buffer = "";
                this.rows.push(row);
                row = [];
            }
            else {
                buffer += c;
                isEscaped = false;
            }
        }

        if(buffer.length > 0) {
            row.push(buffer);
        }

        if(row.length > 0) {
            this.rows.push(row);
        }
    }
}